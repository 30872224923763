<template>
  <div>
    <Row>
      <h2>校友圈详情</h2>
    </Row>
    <br>


    <br>



  </div>
</template>
<script>

export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      search_user_id: '',
      search_subject_id: "",
      search_subject_ids: [0, 1],
      search_deleted: 1,
      search_status: '',

      tableLoading: true,//table加载中

      oneItem: {},
      detailsModel: false,//用户详情弹窗


      columns1: [
        {title: 'id', width: 84, key: 'id'},
        {title: '用户', width: 200, slot: 'user'},
        {title: '内容', width: 350, key: 'content'},
        {title: '图片', width: 150, slot: 'images'},
        {
          title: '信息',
          width: 120,
          key: 'images',
          render: (h, params) => {
            var subject = this.getMomentSubjectName(params.row.subject_id);

            var anonymous = 1 === params.row.anonymous ? '匿名' : '公开';

            var image_length = params.row.images.length;
            var video_length = params.row.video.length;
            var audio_length = params.row.audio.length;

            var comments_count = params.row.comments_count;
            var praise_users_count = params.row.praise_users_count;

            return h('div', [
              h('p', subject + "-" + anonymous),
              h('p', "图:" + image_length + "-视:" + video_length + "-音:" + audio_length),
              h('p', "评:" + comments_count + "-赞:" + praise_users_count)
            ])
          }
        },


        {title: '发布时间', width: 160, key: 'created_at'},
        {
          title: '操作',
          key: 'action',
          width: 220,
          align: 'center',
          render: (h, params) => {
            let subject3_button = h('Button', {
              props: {
                type: 'info',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.setSubject(params.index, 3)
                }
              }
            }, '交易');
            let subject0_button = h('Button', {
              props: {
                type: 'info',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.setSubject(params.index, 0)
                }
              }
            }, '校友圈');

            let details_button = h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.$momentDetails({id: params.row.id})
                }
              }
            }, '详情');

            let del_button = h('Poptip', {
              props: {
                confirm: true,
                transfer: true,
                title: '你确定要删除吗？'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                'on-ok': () => {
                  this.delItem(params.index)
                }
              }
            }, [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
              }, '删除')
            ]);

            let buttons = [details_button];


            //判断显示哪些按钮
            switch (params.row.subject_id) {
              case 0:
              case 1:
              case 2:
                buttons.push(subject3_button);
                break;
              case 3:
                buttons.push(subject0_button);
                break;
            }
            if (!params.row.deleted_at) {
              buttons.push(del_button);
            }

            return h('div', buttons);
          }
        }
      ],
      data1: []
    }
  },
  created() {
    console.log(this.$route.params)
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      var that = this;
      that.tableLoading = true;
      this.data1 = [];
      this.$http.get("/moments", {
        params: {
          page: that.nowPage, word: that.searchValue,
          user_id: that.search_user_id,
          subject_ids: that.search_subject_ids,
          deleted: that.search_deleted,
          status: that.search_status
        }
      }).then(function (res) {
        that.data1 = res.data.data;
        that.pageTotal = res.data.meta.pagination.total;
        that.tableLoading = false;
      });
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.data1[index];
      this.detailsModel = true;
    },
    setSubject(index, subject_id) {
      this.$http.patch('/moment/' + this.data1[index].id + '/subject/' + subject_id).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功',
            duration: 1,
          });
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    setTopStatus(index) {
      this.$http.patch('/moment/' + this.data1[index].id + '/top_status').then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    delItem(index) {
      var that = this;
      this.$http.delete('/moments/' + this.data1[index].id).then(function (resdata) {
        if (200 == resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.addStudentModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },

    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    }
  }
}
</script>
