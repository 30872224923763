<template>
  <div>
    <Row>
      <h2>评论管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入评论内容" style="width:300px"></Input>
        <Input v-model="search_user_id" clearable placeholder="用户id" style="width:80px"></Input>
        <Input v-model="search_moment_id" clearable placeholder="动态id" style="width:80px"></Input>
        <Button shape="circle" icon="ios-search" @click="getComments"></Button>
      </Col>
    </Row>


    <Row>
      <Table border size="small" :columns="commentsColumns" :data="comments">
        <template slot-scope="{ row }" slot="user">
                   <span v-show="undefined!=row.user">
                      <span @click="openUserDetailsModel(row.user.id)">
                        {{ undefined == row.user ? "" : row.user.nickname }}
                      </span>
                     ({{ undefined == row.user ? "" : row.user.id }})
                   </span>
        </template>
        <template slot-scope="{ row }" slot="object_user">
                   <span v-show="undefined!=row.object_user">
                      <span @click="openUserDetailsModel(row.object_user.id)">
                        {{ undefined == row.object_user ? "" : row.object_user.nickname }}
                      </span>
                     ({{ undefined == row.object_user ? "" : row.object_user.id }})
                   </span>
        </template>

      </Table>
    </Row>
    <div style="margin: 10px;overflow: hidden">
      <div style="float: left;">
        <Page :total="commentPageTotal" show-elevator show-total @on-change="changeCommentPage"></Page>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      tableLoading: true,//table加载中
      searchValue: '',//搜索值
      search_user_id: '',
      search_moment_id: '',

      commentsColumns: [
        {title: 'id', width: 90, key: "id"},
        {
          title: '动态', width: 90, key: 'moment_id',
          render: (h, params) => {
            return h('span', {
              on: {
                click: () => {
                  this.$momentDetails({id: params.row.moment_id})
                }
              }
            }, params.row.moment_id)
          }
        },
        {title: '对象', width: 160, slot: "user"},
        {title: '对象', width: 160, slot: "object_user"},
        {title: '内容', width: 300, key: "content"},
        {title: '时间', width: 160, key: "created_at"},
        {
          title: '删除时间',
          key: 'action',
          width: 160,
          align: 'center',
          render: (h, params) => {
            let del_button = h('Poptip', {
              props: {
                confirm: true,
                transfer: true,
                title: '你确定要删除吗？'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                'on-ok': () => {
                  this.delComment(params.row.id)
                }
              }
            }, [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
              }, '删除')
            ]);
            let divs = [];
            if (!params.row.deleted_at) {
              divs.push(del_button);
            } else {
              divs.push(h('span', params.row.deleted_at))
            }
            return h('div', divs);
          }
        }
      ],
      comments: [],
      commentNowPage: 1,//当前页
      commentPageTotal: 0,//总页数
    }
  },
  created() {
    this.getComments();
  },
  methods: {
    //评论
    changeCommentPage(page) {
      this.commentNowPage = page;
      this.getComments();
    },
    getComments() {
      this.comments = [];
      this.$http.get("/moments/comments", {
        params: {
          page: this.commentNowPage,
          user_id: this.search_user_id,
          moment_id: this.search_moment_id,
          word:this.searchValue,
          deleted: 0,
        }
      }).then((res) => {
        this.comments = res.data.data;
        this.commentPageTotal = res.data.meta.pagination.total;
      });
    },
    delComment(commentId) {
      this.$http.delete('/moments/comment/' + commentId).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.getComments();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },
    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    },
  }
}
</script>
