import Vue from 'vue';
import UserDetails from '@/components/userDetails'; //引入刚才写的弹框组件
let AlertConstructor = Vue.extend(UserDetails); // 返回一个“扩展实例构造器”

let UserDetailsModal = (o) => {
    let alertDom = new AlertConstructor({
        //将Alert组件挂载到新创建的div上
        el: document.createElement('div')
    })
    //把Alert组件的dom添加到body里
    document.body.appendChild(alertDom.$el);

    alertDom.id = o.id;
}
export default UserDetailsModal;
