// xx.js文件
import Vue from "vue";
import {Notice} from "view-design";
import moment from "moment";

const serviceApi = {};

serviceApi.install = function (Vue) {
    Vue.prototype.$api = serviceApi
    Vue.api = serviceApi
}


serviceApi.addNum = (x, y) => {
    return x * y
} // 还可以在这个文件里面添加多个函数


serviceApi.UserSendMessage = (user_id, content) => {
    if (user_id <= 0) {
        Notice.error({
            title: '操作失败',
            desc: "请选择要发送的对象"
        });
        return
    }
    if (content.length <= 5) {
        Notice.error({
            title: '操作失败',
            desc: "请输入要发送的内容"
        });
        return
    }
    Vue.$http.post('/message/send_to_user', {
        user_id: user_id,
        content: content
    }).then((res) => {
        if (200 === res.data.code) {
            Notice.success({
                title: '操作成功',
                desc: '发送成功'
            });
        } else {
            Notice.error({
                title: '操作失败',
                desc: res.data.msg
            });
        }
    });
}

serviceApi.UserAddBanSimple = (user_id, reason, day) => {
    serviceApi.UserAddBan({user_id: user_id, type: reason, day: day})
}
serviceApi.UserAddBan = (option, callback) => {
    if (option.user_id === undefined) {
        Notice.error({
            title: '操作失败',
            desc: "请选择要封禁的用户"
        });
        return;
    }


    switch (option.type) {
        case "ad":
            option.reason = "未准守平台要求将广告、兼职等交易内容发布到交易区的规定"
            break
        case "mr":
            option.reason = "发布言语人身攻击或骂人等不当言论"
            break
        case "sq":
            option.reason = "发布不当言论"
            break

    }
    if (option.reason === undefined || option.reason === "") {
        Notice.error({
            title: '操作失败',
            desc: "请输入封禁原因"
        });
        return;
    }

    if (option.end_at === undefined) {
        if (option.day === undefined || option.day <= 0) {
            Notice.error({
                title: '操作失败',
                desc: "请输入封禁天数"
            });
            return;
        }

        option.end_at = moment().add(option.day, "day").format("yyyy-MM-DD HH:mm:ss");
    }


    if (option.targets === undefined || option.targets.length <= 0) {
        option.targets = [1, 2, 3]
    }

    let banItem = {
        user_id: option.user_id,
        targets: option.targets,
        reason: option.reason,
        desc: "",
        end_at: option.end_at,
    };

    Vue.$http.post('/bans', banItem).then((res) => {
        if (200 === res.data.code) {
            Notice.success({
                title: '操作成功',
                desc: '添加成功'
            });

            if (callback !== undefined) {
                callback()
            }
        } else {
            Notice.error({
                title: '操作失败',
                desc: res.data.msg
            });
        }
    })
}

serviceApi.UserCloseBan = (band_id,callback) => {
    Vue.$http.get('/bans/' + band_id + "/close").then((res) => {
        if (200 === res.data.code) {
            Notice.success({
                title: '操作成功',
                desc: '添加成功'
            });
            if (callback!==undefined){
                callback()
            }
        } else {
            Notice.error({
                title: '操作失败',
                desc: res.data.msg
            });
        }
    })
}

serviceApi.MomentSetSubject = (momentID, subject_id, callback) => {
    Vue.$http.patch('/moment/' + momentID + '/subject/' + subject_id).then((res) => {
        if (200 === res.data.code) {
            Notice.success({
                title: '操作成功',
                desc: '操作成功',
                duration: 1,
            });
            if (callback) {
                callback();
            }
        } else {
            Notice.error({
                title: '操作失败',
                desc: res.data.msg
            });
        }
    });
}


export default serviceApi
