<template>
  <div>
    <Row>
      <h2>投诉管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入用户姓名或手机" style="width:300px"></Input>
        <Select v-model="search_status" clearable placeholder="状态" style="width:100px;margin-left: 10px">
          <Option :value="-1">已忽略</Option>
          <Option :value="0">待审核</Option>
          <Option :value="1">已处理</Option>
        </Select>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1">

        <template slot-scope="{ row }" slot="user">
          <span v-if="row.user" @click="openUserDetailsModel(row.user.id)">{{ row.user.nickname }}({{ row.user_id }})</span>
          <span v-else></span>
        </template>

        <template slot-scope="{ row }" slot="item">
          <span v-if="1 === row.item_type" @click="openMomentDetailsModel(row.item_id)">校友圈-{{ row.item_id }}</span>
          <span v-if="2 === row.item_type" @click="openMomentDetailsModelByCommentId(row.item_id)">评论-{{ row.item_id }}</span>
          <span v-if="3 === row.item_type" @click="openUserDetailsModel(row.item_id)">聊天-{{ row.item_id }}</span>
        </template>


        <template slot-scope="{ row }" slot="images">
          <Carousel :height="150" loop>
            <CarouselItem v-for="item in row.images" :key="item">
              <img :src="item" height="150px" width="150px">
            </CarouselItem>
          </Carousel>
        </template>

        <template slot-scope="{ row,index }" slot="status">
          <strong>当前状态: {{ getStatusText(row.status) }}</strong>
          <br>
          <Button v-show="0===row.status" @click="changeStatus(index,-1)" size="small" type="warning">忽略
          </Button>
          <Button v-show="0===row.status" @click="changeStatus(index,1)" size="small" type="success">已处理</Button>
        </template>
      </Table>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
        </div>
      </div>
    </Row>


    <Modal v-model="detailsModel" title="投诉详情">
      <p>id:{{ oneItem.id }}</p>
      <div>
        <img v-for="im in oneItem.images" :key="im.id" :src="im" alt="" width="300px">
      </div>
      <div slot="footer"></div>
    </Modal>

  </div>
</template>
<script>


export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      search_status: 0,//搜索值
      tableLoading: true,//table加载中

      oneItem: {},
      detailsModel: false,//投诉详情弹窗


      columns1: [
        {title: 'id', width: 60, key: 'id'},
        {
          title: '类型', width: 90, key: 'accusation_type.name',
          render: (h, params) => {
            if (params.row.accusation_type) {
              return h('span', params.row.accusation_type.name)
            }
          }
        },
        {title: '举报人', width: 160, slot: 'user'},
        {title: '内容', width: 120, slot: 'item'},
        {title: '时间', width: 160, key: 'created_at'},
        {title: '描述', key: 'content'},
        {title: '图片', width: 180, slot: "images"},
        {title: '状态', width: 140, slot: 'status'},
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [

              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openDetailsModel(params.index)
                  }
                }
              }, '详情'),

              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.index)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],
      data1: []
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      this.data1 = [];
      this.$http.get("/accusations", {
        params: {
          page: this.nowPage,
          status: this.search_status
        }
      }).then((res) => {
        console.log(res.data);
        this.data1 = res.data.data;
        this.pageTotal = res.data.meta.pagination.total;
        this.tableLoading = false;
      });
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.data1[index];
      this.detailsModel = true;
    },


    changeStatus(index, status) {
      this.data1[index].status = status;

      this.$http.patch("/accusations/" + this.data1[index].id + "/status?status=" + status).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },

    getStatusText(status) {
      switch (status) {
        case -1:
          return "已忽略";
        case 0:
          return "待审核";
        case 1:
          return "已处理"
        default:
          return "未知"
      }
    },


    delItem(index) {
      var that = this;
      this.$http.delete('/accusations/' + this.data1[index].id).then(function (resdata) {
        if (200 === resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.addStudentModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },

    openUserDetailsModel(id){
      this.$userDetails({id:id})
    },
    openMomentDetailsModel(id){
      this.$momentDetails({id:id})
    },
    openMomentDetailsModelByCommentId(id){
      this.$momentDetails({commentId:id})
    },
  }
}
</script>
