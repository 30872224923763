<template>
<div>
<Row>
    <h2>角色管理</h2>
</Row>
<br>
<Row>
    <Col span="12">
        <Button type="primary" @click="openAddStudentModel">新增</Button>
    </Col>
</Row>

<br>

<Row>
    <Table border size="small" :loading="tableLoading" :columns="columns1" :data="roleLists"></Table>
</Row>


<Modal v-model="addStudentModel" title="新增编辑角色" @on-ok="addStudent">
    <Form ref="formValidate" :model="oneStudent" :rules="ruleValidate" :label-width="80">
        <Row>
            <Col span="24">
                <FormItem label="角色名称">
                    <Input v-model="oneStudent.name" placeholder=""></Input>
                </FormItem>
            </Col>
        </Row>
        <FormItem>
            <Button type="primary" @click="addStudent">提交</Button>
        </FormItem>
    </Form>
    <div slot="footer"></div>
</Modal>

<Modal v-model="allocationModel" title="分配" @on-ok="addStudent">
    <Tree :data="organs" :show-checkbox="true" @on-check-change="selectedOrgans"></Tree>
    <div slot="footer">
        <Button type="primary" @click="saveAllocationOrgan">提交</Button>
    </div>
</Modal>

</div>
</template>
<script>
    export default {
        data(){
            return {

                tableLoading:true,//table加载中
                addStudentModel:false,//增加学生弹窗
                allocationModel:false,//分配机构弹层
                allocationTeacherId:0,
                chooseOrgans:[],
                organs:[],

                oneStudent:{},
                
                ruleValidate: {
                    text: [
                        { required: true, message: 'The name cannot be empty', trigger: 'blur' }
                    ]
                },
                roleLists:[],
                columns1: [
                    {
                        type: 'index',
                        width: 50,
                        align: 'center'
                    },
                    {title: '名称',key: 'name'},
                    {
                        title: '操作',
                        key: 'action',

                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openAllocationModel(params.index)
                                        }
                                    }
                                }, '菜单权限'),

                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openEditStudentModel(params.index)
                                        }
                                    }
                                }, '编辑'),

                                h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                    this.delStudent(params.index)
                                }
                                }
                                }, '删除')
                            ]);
                        }
                    }
                ]
            }
        },
        created() {
            this.getRoles();
        },
        methods:{
            getRoles(){
                var that=this;
                this.roles=[];
                this.$http.get("/roles").then(function(res){
                    that.roleLists=res.data.data;
                    that.tableLoading=false;
                });
            },
            addStudent() {
                var that=this;
                this.$http.post('/roles',that.oneStudent).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '添加成功'
                        });
                        that.addStudentModel=false;
                        that.getRoles();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },
            openAddStudentModel() {
                this.oneStudent={};
                this.addStudentModel=true;
            },
            openEditStudentModel(index) {
                this.oneStudent={};
                this.oneStudent=this.roleLists[index];
                this.addStudentModel=true;
            },
            openAllocationModel(index){
                var that = this;
                this.allocationTeacherId = this.roleLists[index].id;
                this.$http.get('/roles/'+this.roleLists[index].id+'/menus').then(function(resdata) {
                    console.log(resdata);
                    if (200==resdata.data.code) {
                        that.organs = resdata.data.data;
                        that.allocationModel = true;
                    }else{
                        that.$Notice.error({
                            title: '获取失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },
            delStudent(index) {
                var that=this;
                this.$http.delete('/roles/'+this.roleLists[index].id).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.addStudentModel=false;
                        that.getRoles();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            },

            selectedOrgans(organs){
                this.chooseOrgans = organs;
                console.log(this.chooseOrgans);
            },
            saveAllocationOrgan(){
                var that = this;
                let ids=[];
                for (var i = this.chooseOrgans.length - 1; i >= 0; i--) {
                    ids.push(this.chooseOrgans[i].id);
                }
                if (ids.length<=0) {
                    that.$Notice.success({
                        title: '操作提示',
                        desc: '当前未做修改'
                    });
                    return true;
                }
                this.$http.post('/roles/'+this.allocationTeacherId+'/menus',{ids}).then(function(res) {
                    if (200==res.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '操作成功'
                        });
                        that.allocationModel=false;
                        that.allocationTeacherId=0;
                        that.organs=[];
                        that.chooseOrgans=[];
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: res.data.msg
                        });
                    }
                })
            }
            
        }
    }
</script>