<template>
    <div>
        <Row>
            <h2>版本管理</h2>
        </Row>
        <br>
        <Row>

            <Col span="24">
                <Button type="primary" @click="openAddModel">新增</Button>


                <Input v-model="searchValue" clearable placeholder="请输入搜索内容" style="width:300px"></Input>
                <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
            </Col>
        </Row>

        <br>

        <Row>
            <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1">
              <template slot-scope="{ row }" slot="size">
                {{ `${(row.apk_size / 1024 / 1024).toFixed(2)}MB` }}
              </template>
            </Table>

            <div style="margin: 10px;overflow: hidden">

                <div style="float: right;">
                    <Page :total="pageTotal" :page-size="20" show-elevator show-total @on-change="changePage"></Page>
                </div>
            </div>
        </Row>

        <Modal v-model="editModel" title="编辑版本" width="70%" :mask-closable="false">
            <Form :model="item" :label-width="120">
                <Row>
                    <Col span="8">
                        <FormItem label="当前版本号:">
                            <Input v-model="item.version_no" placeholder=""></Input>
                        </FormItem>
                    </Col>


                    <Col span="8">
                        <FormItem label="当前版本码:">
                            <InputNumber v-model="item.version_code" :min="0"></InputNumber>
                        </FormItem>
                    </Col>

                    <Col span="8">
                        <FormItem label="最小支持版本码:">
                            <InputNumber v-model="item.min_version_code" :min="0"></InputNumber>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <FormItem label="是否强制更新:">
                            <Select v-model="item.force_update" style="width: 120px;">
                                <Option :value="0">非强制更新</Option>
                                <Option :value="1">强制更新</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="15">
                        <FormItem label="apk文件">
                            <Upload type="drag" :on-success="uploadSuccess" :show-upload-list="false" action="/api/backend/versions/upload">
                                <div style="padding: 15px 0">
                                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                                    <p>点击或拖拽上传</p>
                                </div>
                            </Upload>
                            <div v-if="item.apk_url !== ''">已上传文件: {{ item.apk_url }}({{item.apk_size}}) </div>
                            <div>
                              手动填写：<Input placeholder="填写url" v-model="item.apk_url"></Input><Input placeholder="文件大小(单位：b)" v-model="item.apk_size"></Input>
                            </div>
                        </FormItem>
                    </Col>
                </Row>


                <Row>
                    <Col span="20">
                        <FormItem label="内容">
                            <Input type="textarea" :rows="10" v-model="item.update_log"></Input>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem>
                    <Button type="primary" @click="addItem">提交</Button>
                </FormItem>
            </Form>
            <div slot="footer"></div>
        </Modal>


        <Modal v-model="detailsModel" title="更新说明">
            <div v-html="item.update_log"></div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                tableLoading: true,//table加载中
                editModel: false,//增加学生弹窗
                nowPage: 1,//当前页
                pageTotal: 0,//总页数
                site_id:0,//状态
                searchValue:'',//搜索值

                detailsModel: false,


                item: {},
                columns1: [
                    {title: '序号',align: 'center',width:65, key: 'id'},
                    {
                        title: '版本号',
                        width:90,
                        key: 'version_no',
                        render: (h, params) => {
                            const row = params.row;
                            return h('a', {
                                attrs:{
                                    href: row.apk_url,
                                    target:'_blank'
                                }
                            },row.version_no)
                        }
                    },
                    {title: '版本码',align: 'center',width:90, key: 'version_code'},
                    {title: '最小版本码',align: 'center',width:110, key: 'min_version_code'},
                    {title: '文件大小',align: 'center',slot: 'size'},
                    // {title: '更新日志',align: 'center', key: 'update_log'},
                    {
                        title: '状态',
                        width: 110,
                        key: 'force_update',
                        render: (h, params) => {
                            const row = params.row;
                            console.log(params.row)
                            const color = row.force_update ? 'warning' :  'success';
                            const text = row.force_update ? '强制' : '非强制';
                            return h('Button', {
                                props: {
                                    type: color,
                                    size: 'small'
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                        this.changeStatus(params.index)
                                    }
                                }
                            }, text)
                        }
                    },
                    // {title: '下载数',align: 'center', key: 'download_num'},
                    // {title: '更新数',align: 'center', key: 'update_num'},
                    {
                        title: '发布人',
                        key: 'manager_id',
                        render: (h, params) => {
                            const row = params.row;
                            return h('span',row.manager.name)
                        }
                    },
                    {title: '发布时间',align: 'center',width:160, key: 'created_at'},
                    {
                        title: '操作',
                        key: 'action',
                        width: 200,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'success',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openDetailsModel(params.index)
                                        }
                                    }
                                }, '详情'),

                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openEditItemModel(params.index)
                                        }
                                    }
                                }, '编辑'),

                                h('Poptip',{
                                  props: {
                                    confirm: true,
                                    transfer: true,
                                    title: '你确定要删除吗？'
                                  },
                                  on: {
                                    'on-ok': () => {
                                      this.delItem(params.index)
                                    }
                                  }
                                },[
                                  h('Button', {
                                    props: {
                                      type: 'error',
                                      size: 'small'
                                    },
                                  }, '删除')
                                ])
                            ]);
                        }
                    }
                ],
                data1: [],

            }
        },

        created() {
            this.getDataList();
        },
        methods: {
            uploadSuccess (response) {
                if (200 === response.code){
                    this.item.apk_url = response.data.apk_url;
                    this.item.apk_size = response.data.apk_size;
                }else {
                    this.$Notice.error({
                        title: '操作失败',
                        desc: response.msg
                    });
                }
            },
            changePage(page) {
                this.nowPage = page;
                this.getDataList();
            },
            getDataList() {
                var that = this;
                that.tableLoading = true;
                this.data1 = [];
                this.$http.get("/versions", {params:{page:that.nowPage,word:that.searchValue,site_id:that.site_id}}).then(function (res) {
                    that.tableLoading = false;
                    if (200 == res.data.code) {
                        that.data1 = res.data.data;
                        that.pageTotal = res.data.meta.pagination.total;
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: res.data.msg
                        });
                    }
                });
            },
            addItem() {
                var that = this;
                this.$http.post('/versions', this.item).then(function (resdata) {
                    if (200 == resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '添加成功'
                        });
                        that.editModel = false;
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },
            openAddModel() {
                this.item={
                    version_no:'',
                    version_code:1,
                    min_version_code:1,
                    apk_url:'',
                    apk_size:'',
                    update_log:'',
                    force_update:0,
                };
                this.editModel=true;
            },
            openEditItemModel(index) {
                this.item = {};
                this.item = this.data1[index];
                this.item.force_update = this.item.force_update ? 1 : 0
                this.editModel = true;
            },
            openDetailsModel(index) {
                this.item = {};
                this.item = this.data1[index];
                this.item.force_update = this.item.force_update ? 1 : 0
                this.detailsModel = true;
            },
            delItem(index) {
                var that = this;
                this.$http.delete('/versions/' + this.data1[index].id).then(function (resdata) {
                    if (200 == resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.editModel = false;
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            },
            changeStatus(index){
                var that = this;
                this.$http.get('/versions/' + this.data1[index].id+'/status/').then(function (resdata) {
                    if (200 == resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '操作成功'
                        });
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            }

        }
    }
</script>
