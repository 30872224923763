<template>
    <div>
        <Row>
            <h2>题库管理</h2>
        </Row>
        <br>
        <Row>
            <Button type="primary" @click="addItemButton">新增</Button>
        </Row>
        <br>
        <Row>
            <Table border :loading="tableLoading" :columns="tableColumns" :data="tableData"></Table>
        </Row>

        <Modal v-model="editModal" title="新增/编辑">
            <Form :model="oneItemDate" :label-width="80">
                <FormItem label="题库名称">
                    <Input v-model="oneItemDate.name" placeholder="题库名称"></Input>
                </FormItem>


                <Row>
                    <Col span="12">
                        <FormItem label="消耗积分数">
                            <InputNumber :max="100" :min="0" :precision='0'
                                         v-model="oneItemDate.consume_integral"></InputNumber>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem>
                    <Button type="primary" @click="addItem">提交</Button>
                </FormItem>
            </Form>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableLoading: true,
                tableColumns: [
                    {
                        title: '类型名称',
                        key: 'name'
                    },
                    {
                        title: '消耗积分数',
                        key: 'consume_integral'
                    },
                    {
                        title: '单选题',
                        key: 'topic_radio_num'
                    },
                    {
                        title: '多选题',
                        key: 'topic_checkbox_num'
                    },
                    {
                        title: '判断题',
                        key: 'topic_judge_num'
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render: (h, params) => {
                            const row = params.row;
                            const color = row.status === 0 ? 'warning' : 'success';
                            const text = row.status === 0 ? '禁用' : '启用';
                            return h('Button', {
                                props: {
                                    type: color,
                                    size: 'small'
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                        this.changeStatus(params.index)
                                    }
                                }
                            }, text)
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 280,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.editModel(params.index)
                                        }
                                    }
                                }, '编辑'),
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.toTopicsList(params.index)
                                        }
                                    }
                                }, '题目列表'),
                                h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.emptyTopic(params.index)
                                        }
                                    }
                                }, '清空'),
                                h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    on: {
                                        click: () => {
                                            this.remove(params.index)
                                        }
                                    }
                                }, '删除')
                            ]);
                        }
                    }
                ],
                tableData: [],
                editModal: false,
                oneItemDate: {
                    'name': '',
                    'radio_num': 0,
                    'radio_score': 0,
                    'checkbox_num': 0,
                    'checkbox_score': 0,
                    'judge_num': 0,
                    'judge_score': 0,
                    'answer_time': 0,
                    'consume_integral': 0,
                },
            }
        },
        created() {
            this.getList();
        },
        methods: {
            getList() {
                var that = this;
                this.$http.get('/question-banks').then(function (response) {
                    that.tableData = response.data.data;
                    that.tableLoading = false;
                }, function () {
                    that.tableLoading = true;
                });
            },
            addItemButton() {
                this.oneItemDate = {
                    'name': '',
                    'consume_integral': 0,
                };
                this.editModal = true;
            },
            editModel(index) {
                this.oneItemDate = this.tableData[index];
                this.editModal = true;
            },
            addItem() {
                var that = this;
                this.$http.post('/question-bank', this.oneItemDate).then(function (response) {
                    console.log(response.data);
                    if (200 == response.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '操作成功'
                        });
                        that.editModal = false;
                        that.getList();
                    } else {
                        console.log(response.data.msg);
                        that.$Notice.error({
                            title: '操作失败',
                            desc: response.data.msg
                        });
                    }
                }, function () {
                    that.tableLoading = true;
                });
            },
            changeStatus(index) {
                console.log(index);
                var that = this;
                this.$http.get('question-bank/status/' + this.tableData[index].id).then(function (response) {
                    console.log(response.data);
                    if (200 == response.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '修改成功'
                        });
                        that.getList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: response.data.msg
                        });
                    }
                });
            },
            remove(index) {
                var that = this;
                this.$http.delete('/admin/question/banks/' + this.tableData[index].id).then(function (response) {
                    console.log(response.data);
                    if (200 == response.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.getList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: response.data.msg
                        });
                    }
                }, function () {
                    that.tableLoading = true;
                });
            },
            emptyTopic(index) {
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    type: 'load-c',
                                    size: 18
                                }
                            }),
                            h('div', '请耐心等待，此过程需要时间')
                        ])
                    }
                });
                var that = this;
                this.$http.delete('/admin/question/banks/empty/' + this.tableData[index].id).then(function (response) {
                    console.log(response.data);
                    that.$Spin.hide();
                    if (200 == response.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '清空成功'
                        });
                        that.getList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: response.data.msg
                        });
                    }
                }, function () {
                    that.tableLoading = true;
                });
            },

            toTopicsList(index) {
                this.$router.push({
                    name: 'question-banks-topics',
                    params: {
                        question_bank_id:this.tableData[index].id,
                        question_bank_name:this.tableData[index].name
                    }
                })
            },
        }
    }
</script>
