<template>
  <div>
    <Row>
      <h2>卖舍友-分期管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Button type="primary" @click="openAddItemModel">新增</Button>
      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1"></Table>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
        </div>
      </div>
    </Row>


    <Modal v-model="editModel" title="编辑" width="50%">
      <Form :model="item" :label-width="60">
        <Row>
          <Col span="24">
            <FormItem label="名称"><Input v-model="item.name" placeholder=""></Input></FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="描述"><Input v-model="item.desc" placeholder=""></Input></FormItem>
          </Col>
        </Row>
        <Row>

          <Col span="12">
            <FormItem label="时间">
          <DatePicker type="datetimerange"
                      @on-change="editDataChange"
                      :value="[this.item.start_at,this.item.end_at]"
                      placeholder="选择活动的起始时间" style="width: 320px"></DatePicker>
            </FormItem>
          </Col>

        </Row>
        <FormItem>
          <Button type="primary" @click="addItem">提交</Button>
        </FormItem>
      </Form>
      <div slot="footer"></div>
    </Modal>

  </div>
</template>
<script>
export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      tableLoading: true,//table加载中

      editModel: false,//增加弹窗
      item: {
        id: "",
        name: "",
        desc: "",
        start_at: "",
        end_at: "",
      },

      columns1: [
        {title: 'id', width: 60, key: 'id'},
        {title: '名称', width: 260, key: 'name'},
        {title: '简介', width: 260, key: 'desc'},
        {title: '开始时间', width: 150, key: 'start_at'},
        {title: '结束时间', width: 150, key: 'end_at'},
        {
          title: '操作',
          key: 'action',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.openEditItemModel(params.index)
                  }
                }
              }, '编辑')
            ]);
          }
        }
      ],
      data1: []
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    editDataChange(data){
      this.item.start_at=data[0]
      this.item.end_at=data[1]
    },
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      this.data1 = [];
      this.$http.get("/show-wall/period/all", {
        params: {
          page: this.nowPage,
          word: this.searchValue
        }
      }).then((res) => {
        console.log(res.data);
        this.data1 = res.data.data;
        this.pageTotal = res.data.meta.pagination.total;
        this.tableLoading = false;
      });
    },

    openAddItemModel() {
      this.item = {
        id: "",
        name: "",
        desc: "",
        start_at: "",
        end_at: "",
      };
      this.editModel = true;
    },
    openEditItemModel(index) {
      this.item = {
        id: "",
        name: "",
        desc: "",
        start_at: "",
        end_at: "",
      };
      this.item = this.data1[index];
      this.editModel = true;
    },
    addItem() {
      this.$http.post('/show-wall/period', this.item).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          this.editModel = false;
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      })
    },
    delItem(index) {
      this.$http.delete('/managers3333/' + this.data1[index].id).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.addStudentModel = false;
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    }
  }
}
</script>
