<template>
<div>
<Row>
    <h2>个人中心</h2>
</Row>
<br>

<Row>
<Tabs value="tab1">
  <Tab-Pane label="密码修改" name="tab1">
    <Form ref="formValidate" :model="password" :label-width="120">
        
        <FormItem label="旧密码">
            <Input type="password" v-model="password.old_password" placeholder="请输入旧密码">
              <Icon type="md-lock" slot="prepend"></Icon>
            </Input>
        </FormItem>

        <FormItem label="新密码">
            <Input type="password" v-model="password.new_password" placeholder="请输入新密码">
              <Icon type="md-lock" slot="prepend"></Icon>
            </Input>
        </FormItem>

        <FormItem label="重复新密码">
            <Input type="password" v-model="password.new_password2" placeholder="重新输入新密码">
              <Icon type="md-lock" slot="prepend"></Icon>
            </Input>
        </FormItem>

        <FormItem>
            <Button type="primary" @click="changePaaword">修改</Button>
        </FormItem>
    </Form>
  </Tab-Pane>
</Tabs>
</Row>

</div>
</template>
<script>
export default {
    data(){
        return{
            teacher:{

            },
            password:{
              old_password:'',
              new_password:'',
              new_password2:''
            }
        }
    },
    created() {
    },
    methods:{
        changePaaword() {
            var that=this;
            this.$http.post('/password/', this.password).then(function(resdata) {
                console.log(resdata);
                if (200==resdata.data.code) {
                    that.$Notice.success({
                        title: '操作成功',
                        desc: '修改密码成功，将会跳转到登陆界面，重新登陆'
                    });
                    window.location.href="/backend";
                }else{
                    that.$Notice.error({
                        title: '操作失败',
                        desc: resdata.data.msg
                    });
                }
            })
        },
    }
}
</script>
