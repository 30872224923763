import Vue from 'vue'
import App from './App.vue'
import router from './router.js';

import ViewUI from 'view-design';

import 'view-design/dist/styles/iview.css';
import {Message} from "view-design";
import iviewArea from 'iview-area';

Vue.use(ViewUI);
Vue.use(iviewArea);



import moment from 'moment'
// 设置本地时区
moment.locale('zh-cn')
Vue.prototype.$moment = moment

import axios from 'axios'

axios.defaults.baseURL = '/api/backend';
// http request 拦截器
axios.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
axios.interceptors.response.use(
    response => {
        const {code, msg} = response.data
        if (code === 200) {
            return response
        } else {
            Message.error(msg)
            return Promise.reject()
        }
        // return response;
    },
    error => {
        if (error.response) {
            console.log("有响应", error.response)
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    router.replace({path: '/login',})
            }
        }
        //临时解决，不应该如此
        console.log("报错:", error);
        let errString = JSON.stringify(error)
        if (errString.indexOf("XMLHttpRequest") !== -1) {
            router.replace({path: '/login',})
        }

        return Promise.reject(error.response.msg)
    }
);

Vue.$http = Vue.prototype.$http = axios


import serviceApi from "./service/api";
Vue.use(serviceApi)

//用户详情弹窗
import UserDetailsModal from '@/common/userDetails'
Vue.prototype.$userDetails = UserDetailsModal;

//动态详情弹窗
import MomentDetailsModal from '@/common/momentDetails'
Vue.prototype.$momentDetails = MomentDetailsModal;

//公共业务函数
import baseBusiness from './common/baseBusiness'
Vue.use(baseBusiness);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router: router,
}).$mount('#app')
