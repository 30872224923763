<template>
  <div>
    <Row>
      <h2>卖舍友管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <router-link to="/show-wall-periods-list">
          <Button type="primary">查看期列表</Button>
        </router-link>

        <Input v-model="search_sponsor_user_id" clearable placeholder="发起人"
               style="width:80px;margin-left: 10px"></Input>
        <Input v-model="search_user_id" clearable placeholder="被卖人" style="width:80px;margin-left: 10px"></Input>
        <Select v-model="search_period_id" clearable placeholder="期" style="width:300px;margin-left: 10px">
          <Option :value="0">未发布</Option>
          <Option v-for="period in periods" :key="period.id" :value="period.id">{{ period.name }}</Option>
        </Select>

        <Select v-model="search_status" clearable placeholder="状态" style="width:100px;margin-left: 10px">
          <Option :value="-1">未通过</Option>
          <Option :value="0">待审核</Option>
          <Option :value="1">已通过</Option>
          <Option :value="-3">已过期</Option>
          <Option :value="-2">已撤销</Option>
        </Select>


        <Button shape="circle" icon="ios-search" @click="getDataList" style="margin-left: 10px"></Button>
      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1">
        <template slot-scope="{ row }" slot="avatar">
          <Carousel :height="150" loop>
            <CarouselItem v-for="item in row.images" :key="item">
              <img :src="item" height="150px" width="150px">
            </CarouselItem>
          </Carousel>
        </template>

        <template slot-scope="{ row }" slot="user">
          <div @click="openUserDetailsModel(row.sponsor_user.id)">
            <strong>发布人:</strong>{{ row.sponsor_user.nickname }}({{ row.sponsor_user.major }})
          </div>
          <br>
          <div @click="openUserDetailsModel(row.user.id)">
            <strong>被卖人:</strong>{{ row.user.nickname }}({{ row.user.major }})
          </div>
          <div><strong>被卖人称呼:</strong>{{ row.nickname }}</div>
          <div><strong>被卖人性别:</strong>{{ getGenderText(row.user.gender) }}</div>
        </template>

        <template slot-scope="{ row,index }" slot="status">
          <strong>当前状态: {{ getStatusText(row.status) }}</strong>
          <br>
          <br>
          <Button v-show="0===row.status" @click="changeShowWallStatus(index,-1)" size="small" type="warning">未通过
          </Button>
          <Button v-show="0===row.status" @click="changeShowWallStatus(index,1)" size="small" type="success">通过
          </Button>
        </template>
      </Table>
    </Row>

    <div style="margin: 10px;overflow: hidden">
      <div style="float: left;">
        <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
      </div>
    </div>

    <Modal v-model="editModel" title="编辑" width="50%">
      <Form :model="item" :label-width="60">
        <Row>
          <Col span="24">
            <FormItem label="期">
              <Select v-model="item.period_id">
                <Option v-for="period in periods" :key="period.id" :value="period.id">{{ period.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="状态">
              <Select v-model="item.status">
                <Option :value="-1">未通过</Option>
                <Option :value="0">待审核</Option>
                <Option :value="1">已通过</Option>
                <Option :value="-3">已过期</Option>
                <Option :value="-2">已撤销</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>

        <FormItem>
          <Button type="primary" @click="addItem">提交</Button>
        </FormItem>
      </Form>
      <div slot="footer"></div>
    </Modal>

    <Modal v-model="detailsModel" width="60%" title="详情">
      <div v-if="item.user">
        <strong>被卖的</strong>
        <Avatar size="80" :src="item.user.avatar"/>
        {{ item.user.nickname }}(ID:{{ item.user.id }})；{{ item.user.major }}({{
          item.user.grade
        }}级),{{ getGenderText(item.user.gender) }}
      </div>

      <div v-if="item.sponsor_user">
        <strong>发起人</strong>
        <Avatar size="80" :src="item.sponsor_user.avatar"/>
        {{ item.sponsor_user.nickname }}(ID:{{ item.sponsor_user.id }})；{{
          item.sponsor_user.major
        }}({{ item.sponsor_user.grade }}级),{{ getGenderText(item.sponsor_user.gender) }}
      </div>

      <div>
        <strong>昵称:</strong>{{ item.nickname }}<br>
        <strong>属性:</strong>{{ item.attributes }}<br>
        <strong>心动类型:</strong>{{ item.heart_types }}<br>
      </div>
      <div>
        <img v-for="im in item.images" :key="im.id" :src="im" alt="" width="300px">
      </div>

      <div slot="footer"></div>
    </Modal>

  </div>
</template>
<script>

export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      search_sponsor_user_id: "",
      search_user_id: "",
      search_period_id: "",
      search_status: 0,

      tableLoading: true,//table加载中

      item: {},
      editModel: false,
      detailsModel: false,//用户详情弹窗

      periods: [],

      columns1: [
        {title: 'id', width: 60, key: 'id'},
        {
          title: '头像',
          width: 180,
          key: 'avatar',
          slot: "avatar"
        },
        {
          title: '发布人',
          width: 250,
          key: 'avatar',
          slot: "user"
        },
        {
          title: '概况',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.like_num + "人喜欢"),
              h('p', params.row.dislike_num + "人不喜欢"),
            ])
          }
        },
        {
          title: '期',
          width: 80,
          render: (h, params) => {
            let period_id = params.row.period_id
            if (0 === period_id) {
              return h('span', '未发布');
            } else {
              return h('span', params.row.period.name);
            }
          }
        },
        {title: '发表日期', width: 150, key: 'created_at'},
        {
          title: '状态',
          align: "center",
          width: 150,
          slot: 'status'
        },
        {
          title: '操作',
          key: 'action',
          width: 190,
          align: 'center',
          render: (h, params) => {
            return h('div', [

              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '4px'
                },
                on: {
                  click: () => {
                    this.openDetailsModel(params.index)
                  }
                }
              }, '详情'),

              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '4px'
                },
                on: {
                  click: () => {
                    this.openEditModel(params.index)
                  }
                }
              }, '编辑'),

              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delItem(params.index)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],
      data1: []
    }
  },
  created() {
    this.getDataList();
    this.getPeriodsList();
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      this.data1 = [];
      this.$http.get("/show-walls", {
        params: {
          page: this.nowPage,
          word: this.searchValue,
          sponsor_user_id: this.search_sponsor_user_id,
          user_id: this.search_user_id,
          period_id: this.search_period_id,
          status: this.search_status,
        }
      }).then((res) => {
        console.log(res.data);
        this.data1 = res.data.data;
        this.pageTotal = res.data.meta.pagination.total;
        this.tableLoading = false;
      });
    },
    getPeriodsList() {
      let that = this;
      this.$http.get("/show-wall/period/all", {params: {per_page: 100}}).then(function (res) {
        that.periods = res.data.data;
      });
    },
    openEditModel(index) {
      this.item = {};
      this.item = this.data1[index];
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.item = {};
      this.item = this.data1[index];
      this.detailsModel = true;
    },
    addItem() {
      let that = this;
      this.$http.post('/show-wall', this.item).then(function (resdata) {
        if (200 === resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          that.editModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      })
    },
    delItem(index) {
      var that = this;
      this.$http.delete('/managers/' + this.data1[index].id).then(function (resdata) {
        if (200 === resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.addStudentModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },

    changeShowWallStatus(index, status) {
      this.data1[index].status = status;

      this.$http.patch("/show-wall/" + this.data1[index].id + "/status?status=" + status).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },

    getStatusText(status) {
      switch (status) {
        case -3:
          return "已过期";
        case -2:
          return "已撤销";
        case -1:
          return "未通过";
        case 0:
          return "待审核";
        case 1:
          return "已通过"
        default:
          return "未知"
      }
    },
    getGenderText(gender) {
      switch (gender) {
        case 1:
          return "男";
        case 2:
          return "女";
        default:
          return "未知"
      }
    },
    openUserDetailsModel(id){
      this.$userDetails({id:id})
    },
  }
}
</script>
