<template>
<div>
<Row>
    <h2>用户管理</h2>
</Row>
<br>
<Row>
    <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入用户姓名或手机" style="width:300px"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
    </Col>
</Row>

<br>

<Row>
    <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1"></Table>
    <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
            <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
        </div>
    </div>
</Row>


<Modal v-model="detailsModel" title="用户详情">
    <p>id:{{oneItem.id}}</p>
    <p>小程序:{{oneItem.xcx_openid}}</p>
    <p>手机号:{{oneItem.phone}}</p>
    <p>真实姓名:{{oneItem.name}}</p>
    <p>学号或工号:{{oneItem.number}}</p>
    <p>身份:{{oneItem.identity}};(0游客1学生2老师)</p>
    <p>学校:{{oneItem.school}}</p>
    <p>学院:{{oneItem.college}}</p>
    <p>年级:{{oneItem.grade}}</p>
    <p>专业:{{oneItem.major}}</p>
    <p>班级:{{oneItem.class_name}}</p>
    <p>教务系统密码:{{oneItem.password_jw}}</p>
    <p>图书馆系统密码:{{oneItem.password_lib}}</p>
    <p>一卡通系统密码:{{oneItem.password_ykt}}</p>
    <p>昵称:{{oneItem.nickname}}</p>
    <p>头像:{{oneItem.avatar}}</p>
    <p>性别:{{oneItem.gender}};(1时是男性，2女性，0未知)</p>
    <p>生日:{{oneItem.birthday}}</p>
    <p>年龄:{{oneItem.age}}</p>
    <p>星座:{{oneItem.constellation}}</p>
    <p>个性签名:{{oneItem.introduction}}</p>
    <p>积分:{{oneItem.integral}}</p>
    <p>状态:{{oneItem.status}};(-1被禁用,0未注册,1正常)</p>
    <div slot="footer"></div>
</Modal>


</div>
</template>
<script>
    export default {
        data(){
            return {
                nowPage:1,//当前页
                pageTotal:0,//总页数
                searchValue:'',//搜索值
                tableLoading:true,//table加载中

                oneItem:{},
                detailsModel:false,//用户详情弹窗

                

                columns1: [
                    {title: 'id',width: 60,key: 'id'},
                    {
                        title: '头像',
                        width: 80,
                        key: 'avatar',
                        render: (h, params) => {
                            var avatar = params.row.avatar;
                            if (!avatar){
                                avatar = 'https://files.xiaoqing.xyz/suse-online-logo.png';
                            }
                            return h('avatar', {
                                attrs: {
                                    src: avatar,
                                    width:'100px',
                                    height:'100px',
                                }
                            })
                        }
                    },
                    {title: '昵称',key: 'nickname'},
                    {
                        title: '账号',
                        width: 270,
                        key: 'xcx_openid',
                        render: (h, params) => {
                            var phone = params.row.phone;
                            if (!phone){
                                phone = params.row.xcx_openid;
                            }
                            return h('span', phone)
                        }
                    },
                    {title: '姓名',key: 'name'},
                    {title: '学号',key: 'number'},
                    {
                        title: '性别',
                        width: 70,
                        key: 'gender',
                        render: (h, params) => {
                            const gender = params.row.gender;
                            var text = '';
                            switch (gender){
                                case 0:
                                    text='未知';
                                    break;
                                case 1:
                                    text='男';
                                    break;
                                case 2:
                                    text='女';
                                    break;
                            }
                            return h('span', text)
                        }
                    },
                    {
                        title: '身份',
                        width: 70,
                        key: 'identity',
                        render: (h, params) => {
                            const identity = params.row.identity;
                            var text = '游客';
                            switch (identity){
                                case 0:
                                    text='游客';
                                    break;
                                case 1:
                                    text='学生';
                                    break;
                                case 2:
                                    text='老师';
                                    break;
                            }
                            return h('span', text)
                        }
                    },
                    {
                        title: '状态',
                        width: 80,
                        key: 'status',
                        render: (h, params) => {
                            const status = params.row.status;
                            var color = '';
                            var text = '';
                            switch (status){
                                case 0:
                                    color='primary';
                                    text='未注册';
                                    break;
                                case 1:
                                    color='success';
                                    text='正常';
                                    break;
                                case -1:
                                    color='warning';
                                    text='禁用';
                                    break;
                            }
                            return h('Button', {
                                    props: {
                                        type: color,
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            //this.changeStudentStaus(params.index)
                                        }
                                    }
                                }, text)
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 150,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [

                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openDetailsModel(params.index)
                                        }
                                    }
                                }, '详情'),

                                h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                    this.delItem(params.index)
                                }
                                }
                                }, '删除')
                            ]);
                        }
                    }
                ],
                data1: []
            }
        },
        created() {
            this.getDataList();
        },
        methods:{
            changePage(page) {
                this.nowPage=page;
                this.getDataList();
            },
            getDataList(){
                var that=this;
                that.tableLoading=true;
                this.data1=[];
                this.$http.get("/users",{params:{page:that.nowPage,word:that.searchValue}}).then(function(res){
                    console.log(res.data);
                    that.data1=res.data.data;
                    that.pageTotal=res.data.meta.pagination.total;
                    that.tableLoading=false;
                });
            },
            openDetailsModel(index) {
                this.oneItem={};
                this.oneItem=this.data1[index];
                this.detailsModel=true;
            },
            delItem(index) {
                var that=this;
                this.$http.delete('/managers/'+this.data1[index].id).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.addStudentModel=false;
                        that.getDataList();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            }
        }
    }
</script>