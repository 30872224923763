import Vue from 'vue'
import Router from 'vue-router'


import login from './views/login.vue'
import layout from './views/layout.vue'

import index from './views/index/index.vue'
import indexUser from './views/index/user.vue'

import manager from './views/manager/index.vue'
import role from './views/role/role.vue'
import users from './views/users/index.vue'
import articles from './views/articles/index.vue'

import versions from './views/versions/index.vue'


import moments from './views/moments/index.vue'
import momentsPhone from './views/moments/phone.vue'
import momentDetails from './views/moments/details.vue'

import comments from './views/comments/index.vue'
import media from './views/media/index.vue'
import feedbacks from './views/feedbacks/index.vue'
import sites from './views/sites/index.vue'
import classrooms from './views/classrooms/index.vue'
import empty_classrooms from './views/empty_classrooms/index.vue'

import statistics from './views/statistics/index.vue'
import questionBanks from './views/question-banks/index.vue'
import questionBanksTopics from './views/question-banks/topics.vue'
import applicationList from './views/application/list.vue'
import InstitutionList from './views/institution/index.vue'

import ShowWallList from './views/show-wall/index.vue'
import ShowWallPeriodsList from './views/show-wall/periods.vue'

import Shop from './views/shop/shop.vue'
import Accusations from './views/accusation/index.vue'
import Ban from './views/ban/index.vue'


Vue.use(Router);

let routes = [
    {path: '/', name:'index', meta: { title: '首页'}, component: index},
    {path: '/index', name:'index', meta: { title: '首页'}, component: index},
    {path: '/index-user', name:'teacher', meta: { title: '个人中心'}, component: indexUser},
    {path: '/manager', name:'manager', meta: { title: '管理员管理'}, component: manager},
    {path: '/role', name:'role', meta: { title: '角色管理'}, component: role},
    {path: '/users', name:'users', meta: { title: '用户管理'}, component: users},
    {path: '/articles', name:'articles', meta: { title: '新闻管理'}, component: articles},
    {path: '/versions', name:'versions', meta: { title: '版本管理'}, component: versions},

    {path: '/moments', name:'moments', meta: { title: '校友圈管理'}, component: moments},
    {path: '/moments/333', name:'moments_details', meta: { title: '校友圈详情'}, component: momentDetails},
    // {path: '/moments_phone', name:'moments_phone', meta: { title: '校友圈管理'}, component: momentsPhone},
    {path: '/comments', name:'comments', meta: { title: '评论管理'}, component: comments},
    {path: '/media', name:'media', meta: { title: '多媒体管理'}, component: media},
    {path: '/feedbacks', name:'feedbacks', meta: { title: '反馈管理'}, component: feedbacks},
    {path: '/sites', name:'sites', meta: { title: '新闻站点管理'}, component: sites},
    {path: '/classrooms', name:'classrooms', meta: { title: '教室管理'}, component: classrooms},
    {path: '/empty_classrooms', name:'empty_classrooms', meta: { title: '空教室管理'}, component: empty_classrooms},

    {path: '/statistics', name:'statistics', meta: { title: '统计管理'}, component: statistics},
    {path: '/question-banks', name:'question-banks', meta: { title: '题库管理'}, component: questionBanks},
    {path: '/question-banks-topics', name:'question-banks-topics', meta: { title: '题库管理'}, component: questionBanksTopics},
    {path: '/application-list', name:'application-list', meta: { title: '应用管理'}, component: applicationList},
    {path: '/institution-list', name:'institution-list', meta: { title: '机构管理'}, component: InstitutionList},
    {path: '/accusations', name:'accusations', meta: { title: '投诉管理'}, component: Accusations},
    {path: '/bans', name:'bans', meta: { title: '封禁管理'}, component: Ban},

    {path: '/show-wall-list', name:'show-wall-list', meta: { title: '卖舍友'}, component: ShowWallList},
    {path: '/show-wall-periods-list', name:'show-wall-periods-list', meta: { title: '卖舍友周期'}, component: ShowWallPeriodsList},
    {path: '/shop', name:'shop', meta: { title: '商铺管理'}, component: Shop},
]


const router = new Router({
    mode: 'history',
    routes: [
        {path: '/login', component: login},
        {path: '/moments_phone', component: momentsPhone},
        {path: '/', component: layout, children: routes}
    ]
});

router.beforeEach((to, from, next) => {
    if ("/login" !== to.path) {
        if (window.localStorage.getItem('token')) {
            next();
        } else {
            next({path: '/login'})
        }
    } else {
        next();
    }
})

export default router
