import Vue from 'vue';
import UserDetails from '@/components/momentDetails'; //引入刚才写的弹框组件
let AlertConstructor = Vue.extend(UserDetails); // 返回一个“扩展实例构造器”

let MomentDetailsModal = (o) => {
    let alertDom = new AlertConstructor({
        //将Alert组件挂载到新创建的div上
        el: document.createElement('div')
    })
    //把Alert组件的dom添加到body里
    document.body.appendChild(alertDom.$el);

    if (o.id !== undefined){
        alertDom.id = o.id;
    }
    if (o.commentId !== undefined){
        alertDom.commentId = o.commentId;
    }

}
export default MomentDetailsModal;
