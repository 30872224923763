<template>
  <div>
    <Row>
      <h2>封禁管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Button type="primary" @click="openAddItemModel">新增</Button>

        <Input v-model="searchValue" clearable placeholder="请输入封禁原因" style="width:300px"></Input>


        <Input v-model="search_user_id" clearable placeholder="用户id" style="width:80px"></Input>


        <Select v-model="search_effect" clearable placeholder="是否生效" style="width:100px">
          <Option :value="0" :key="0">全部</Option>
          <Option :value="1" :key="1">生效中</Option>
          <Option :value="-1" :key="-1">已失效</Option>
        </Select>

        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>


      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="bansColumns" :data="bans">
        <template slot-scope="{ row }" slot="user">
          <span @click="openUserDetailsModel(row.user.id)">{{ row.user.nickname }}({{ row.user.id }})</span>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button v-if="(checkIsAble(row))"
              type="primary"
                  size="small"
                  style="margin-right: 5px;"
                  @click="$api.UserCloseBan(row.id,getDataList)">结束</Button>
          <Poptip title="你确定要删除这条吗？" @on-ok="delItem(row.id)" confirm transfer>
            <Button type="error" size="small" style="margin-right: 5px;">删除</Button>
          </Poptip>
        </template>
      </Table>
    </Row>

    <div style="margin: 10px;overflow: hidden">
      <div style="float: left;">
        <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
      </div>
    </div>


    <Modal v-model="addItemModel" title="新增" width="50%">
      <Form :model="item" :label-width="60">
        <Row>
          <Col span="24">
            <FormItem label="用户"><Input v-model="item.user_id" placeholder=""></Input></FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="目标">
              <CheckboxGroup v-model="item.targets">
                <Checkbox label="1">校友圈</Checkbox>
                <Checkbox label="2">评论</Checkbox>
                <Checkbox label="3">私聊</Checkbox>
              </CheckboxGroup>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="时间">
              <DatePicker type="datetime"
                          @on-change="chooseDataChange"
                          :options="addBanChooseDateOptions"
                          :value="this.item.end_at"
                          placeholder="选择结束时间" style="width: 320px"></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="原因">
              <Select @on-change="addBanReasonChooseChange">
                <Option value="发布言语人身攻击或骂人等不当言论">骂人、人身攻击</Option>
                <Option value="未准守平台要求将广告、兼职等交易内容发布到交易区的规定">发布广告</Option>
              </Select>
              <Input v-model="item.reason" placeholder=""></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="描述"><Input v-model="item.desc" placeholder=""></Input></FormItem>
          </Col>
        </Row>

        <FormItem>
          <Button type="primary" @click="addItem">提交</Button>
        </FormItem>
      </Form>
      <div slot="footer"></div>
    </Modal>


  </div>
</template>
<script>

export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      search_user_id: '',
      search_effect: 0,

      tableLoading: true,//table加载中

      reply_content: "",

      item: {
        user_id: "",
        targets: [],
        reason: "",
        desc: "",
        end_at: "",
      },
      addItemModel: false,
      addBanChooseDateOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
        shortcuts: [
          {
            text: '1天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              return date;
            }
          },
          {
            text: '2天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
              return date;
            }
          },
          {
            text: '3天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
              return date;
            }
          },
          {
            text: '1周',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              return date;
            }
          },
          {
            text: '2周',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 15);
              return date;
            }
          },
          {
            text: '1月',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              return date;
            }
          },
          {
            text: '2月',
            value() {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 60);
              return date;
            }
          },
        ]
      },

      bansColumns: [
        {title: '用户', width: 180, slot: 'user'},
        {
          title: '时间', width: 340, key: 'time',
          render: (h, params) => {
            let created_at = this.$moment(params.row.created_at);
            let end_at = this.$moment(params.row.end_at);
            let now = this.$moment();
            let d = end_at.diff(created_at, 'days')
            if (now.isBefore(end_at)) {
              return h('Tag', {props: {color: "success"}}, params.row.created_at + "-" + params.row.end_at + " " + d + "天")
            } else {
              return h('Tag', {props: {color: "default"}}, params.row.created_at + "-" + params.row.end_at + " " + d + "天")
            }
          }
        },
        {
          title: '目标', width: 80, key: 'target',
          render: (h, params) => {
            switch (params.row.target) {
              case 1:
                return h('span', "校友圈")
              case 2:
                return h('span', "评论")
              case 3:
                return h('span', "私聊")
            }
          }
        },
        {
          title: '理由', width: 400, key: 'target',
          render: (h, params) => {
            return h('span', params.row.reason +";"+ params.row.desc)
          }
        },

        {title: '操作', key: 'action', width: 136, align: 'center', slot: 'action'}
      ],
      bans: []
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      this.bans = [];
      this.$http.get("/bans", {
        params: {
          page: this.nowPage,
          word: this.searchValue,
          user_id: this.search_user_id,
          effect: this.search_effect,
        }
      }).then((res) => {
        this.bans = res.data.data;
        this.pageTotal = res.data.meta.pagination.total;
        this.tableLoading = false;
      });
    },
    openAddItemModel() {
      this.oneItem = {};
      this.oneItem = {
        user_id: "",
        targets: [],
        reason: "",
        desc: "",
        end_at: "",
      };
      this.addItemModel = true;
    },
    chooseDataChange(data) {
      this.item.end_at = data
    },
    addBanReasonChooseChange(value) {
      this.item.reason = value
    },
    addItem() {
      if (this.item.targets.length <= 0) {
        this.$Notice.error({
          title: '操作失败',
          desc: "请选择要封禁的对象"
        });
        return;
      }
      this.$http.post('/bans', this.item).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          this.addItemModel = false;
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      })
    },

    delItem(id) {
      this.$http.delete(`/bans/${id}`).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },
    checkIsAble(row){
      return this.$moment().isBefore(this.$moment(row.end_at))
    },
    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    }
  }
}
</script>
