<style scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout-header-bar {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}

.layout-menu-left {
    background: #fff;
    padding-top: 20px;
}

.layout-logo-left {
    width: 80%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 10px auto;
}

.menu-icon {
    transition: all .3s;
}

.rotate-icon {
    transform: rotate(-90deg);
}

.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
}

.menu-item i {
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
}

.collapsed-menu .ivu-menu-item{
    padding: 12px 12px;
}


.collapsed-menu span {
    width: 0px;
    transition: width .2s ease;
}

.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 18px;
}
</style>
<template>
    <div class="layout">
        <Layout>
            <Sider ref="side1" :style="{paddingTop:'50px'}" hide-trigger :width="130" collapsible :collapsed-width="50" v-model="isCollapsed" >
                <Menu :style="{minHeight:'100vh'}" :active-name="activeMenu" theme="dark" width="auto" :class="menuitemClasses"
                      @on-select="selectMenu">
                    <MenuItem v-for="menu_item in menuList" :key="menu_item.id" :name="menu_item.router">
                        <Icon :type="menu_item.ico"></Icon>
                        <span>{{ menu_item.name }}</span>
                    </MenuItem>
                </Menu>
            </Sider>
            <Layout>
                <Header :style="{padding: 0}" class="layout-header-bar">
                    <Icon @click.native="collapsedSider" :class="rotateIcon" :style="{margin: '0 10px'}" type="md-menu"
                          size="24"></Icon>
                </Header>
                <Content :style="{margin: '5px',padding:'10px', background: '#fff', minHeight: '300px'}">
                    <router-view></router-view>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>
export default {
    name: 'app',
    data() {
        return {
            menuList: '',
            activeMenu: '',
            user: {},
            isCollapsed: false
        }
    },
    computed: {
        rotateIcon() {
            return [
                'menu-icon', this.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        menuitemClasses() {
            return [
                'menu-item', this.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
    },
    created() {
      if (window.screen.width<=500){
        this.isCollapsed=true
      }
        this.getMenu();
    },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', ()=>{
        this.isCollapsed=window.innerWidth<=500
      });
    })
  },
    watch: {// 路由控制菜单选中状态
        '$route'(to) {
            this.activeMenu = to.name;
        },
    },
    methods: {
        collapsedSider() {
            this.$refs.side1.toggleCollapse();
        },
        selectMenu(name) {
            this.$router.push(name)
        },
        getMenu() {
            this.$http.get('/menus').then((response) => {
                this.menuList = response.data.data.menus;
                this.user = response.data.data.user;
            }).catch((response) => {
                console.log('失败:' + response);
            })
        },
        logout() {
            localStorage.setItem("token", "");
            this.$router.push("/login")
        }
    }
}
</script>
