<template>
    <div>
        <Modal
                v-model="visible"
                title="商铺"
                width="1000"
                :styles="{top: 0}"
                @on-ok="handlerSubmit"
        >
            <Form :label-width="80">
                <FormItem label="商铺名:">
                    <Input placeholder="请输入商铺名" v-model="data.name"/>
                </FormItem>
                <FormItem label="logo">
                    <div class="demo-upload-list" v-if="data.sign">
                        <img :src="data.sign" alt="">
                        <div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(data.sign)"></Icon>
                            <Icon type="ios-trash-outline" @click.native="handleRemove(data.sign)"></Icon>
                        </div>
                    </div>
                    <Upload
                            ref="upload"
                            :on-success="handleSuccess"
                            :format="['jpg','jpeg','png']"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            :show-upload-list="false"
                            action="/backend/upload"
                            type="drag"
                            style="display: inline-block;width:58px;"
                    >
                        <div style="width: 58px;height:58px;line-height: 58px;">
                            <Icon type="ios-camera" size="20"></Icon>
                        </div>
                    </Upload>
                    <Modal title="View Image" v-model="imageVisible">
                        <img :src="ViewImageUrl" v-if="imageVisible" style="width: 100%">
                        <div slot="footer"></div>
                    </Modal>
                </FormItem>
                <FormItem label="商铺电话:">
                    <Input placeholder="请输入商铺电话" v-model="data.telephone"/>
                </FormItem>
                <FormItem label="简介:">
                    <Input type="textarea" placeholder="请输入商铺简介" v-model="data.introduction"/>
                </FormItem>
                <FormItem label="图文介绍:">
                    <editor v-model="data.description"></editor>
                </FormItem>
                <FormItem label="省市区">
                    <al-cascader v-model="region" level="2" data-type="code"/>
                </FormItem>
                <FormItem label="商圈代码:">
                    <Input placeholder="请输入商圈代码" v-model="data.business_code"/>
                </FormItem>
                <FormItem label="地区:">
                    <Input placeholder="请输入地区" v-model="data.area"/>
                </FormItem>
                <FormItem label="地址:">
                    <Input placeholder="请输入地址" v-model="data.address"/>
                </FormItem>
                <FormItem label="经度:">
                    <Input placeholder="请输入经度" v-model="data.latitude"/>
                </FormItem>
                <FormItem label="纬度:">
                    <Input placeholder="请输入纬度" v-model="data.longitude"/>
                </FormItem>
                <FormItem label="评分/得分:">
                    <Input placeholder="请输入评分/得分" v-model="data.rating"/>
                </FormItem>
                <FormItem label="人均消费:">
                    <Input placeholder="请输入人均消费" v-model="data.cost"/>
                </FormItem>
                <FormItem label="状态:">
                    <i-switch v-model="data.status" :true-value="1" :false-value="0"></i-switch>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>
    import editor from "../../../components/editor";

    export default {
        name: "ShopModal",
        components: {editor},
        data() {
            return {
                visible: false,
                imageVisible: false,
                data: {},
                ViewImageUrl: '',
                region: []
            }
        },
        methods: {
            edit(record) {
                this.data = {...record}
                this.region = record.province_code && record.city_code && record.district_code ? [record.province_code.toString(), record.city_code.toString(), record.district_code.toString()] : []
                this.visible = true
            },
            add() {
                this.edit({})
            },
            async handlerSubmit() {
                let data = {...this.data}
                try {
                    if (data.id) {
                        await this.$http.post('shop/update', data)
                    } else {
                        await this.$http.post('shop/store', data)
                    }
                    this.visible = false
                    this.$Message.success("操作成功")
                    this.$emit('complete')
                } catch (e) {
                    console.log(e)
                }
            },
            handleView(url) {
                this.ViewImageUrl = url;
                this.imageVisible = true;
            },
            handleRemove() {
                this.data.sign = null
            },
            handleFormatError(file) {
                this.$Notice.warning({
                    title: 'The file format is incorrect',
                    desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
                });
            },
            handleMaxSize(file) {
                this.$Notice.warning({
                    title: 'Exceeding file size limit',
                    desc: 'File  ' + file.name + ' is too large, no more than 2M.'
                });
            },
            handleSuccess(res, file) {
                file.url = res.data.file_path;
                file.name = res.data.file_name;
                this.$Message.success('上传成功')
                this.data.sign = file.url;
            },
        },
        watch: {
            region: function (newVal) {
                this.data.province_code = newVal[0]
                this.data.city_code = newVal[1]
                this.data.district_code = newVal[2]
            }
        }
    }
</script>

<style scoped>
    .details img {
        width: 100%;
    }

    .demo-upload-list {
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        margin-right: 4px;
    }

    .demo-upload-list img {
        width: 100%;
        height: 100%;
    }

    .demo-upload-list-cover {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .6);
    }

    .demo-upload-list:hover .demo-upload-list-cover {
        display: block;
    }

    .demo-upload-list-cover i {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>