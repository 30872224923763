<template>
  <div>
    <Modal v-model="show"
           :width="modelWidth"
           draggable
           sticky
           reset-drag-position
           :mask="false"
           :footer-hide="true">
      <p slot="header">
        <span>动态详情</span>-ID:{{ oneItem.id }}-用户:{{ oneItem.user.nickname }}({{ oneItem.user.id }})
      </p>
      <Spin size="large" fix v-if="loading"></Spin>

      <Tabs value="moment" style="max-height:550px;overflow:auto;">
        <TabPane label="基本信息" name="moment">

          <Row v-if="oneItem.deleted_at == null" :gutter="10">
            <Col>
              <Poptip
                  confirm
                  title="确定要删除吗？"
                  @on-ok="delItem">
                <Button type="error">删除</Button>
              </Poptip>
            </Col>
            <Col v-if="oneItem.top_status==1">
              <Button type="primary" @click="setTopStatus(oneItem.id)">取消置顶</Button>
            </Col>
            <Col v-if="oneItem.top_status!=1">
              <Button type="primary" @click="setTopStatus(oneItem.id)">置顶</Button>
            </Col>
            <Col v-if="oneItem.subject_id!=3">
              <Button type="primary" @click="$api.MomentSetSubject(oneItem.id,3,getMomentDetails)">交易</Button>
            </Col>
            <Col v-if="oneItem.subject_id!=0">
              <Button type="primary" @click="$api.MomentSetSubject(oneItem.id,0,getMomentDetails)">校友圈</Button>
            </Col>
            <Col>
              <Button type="warning" @click="
                    $api.UserSendMessage(oneItem.user_id,'【违规提醒】同学你好:请将交易类信息发布到[应用]标签下的[交易]模块中。若再违反此规则，平台将采取相应措施对账号进行禁言处理')
                  ">交易警告</Button>


              <Button style="margin-left: 5px" type="warning" @click="
                    $api.UserAddBanSimple( oneItem.user_id,'ad',3)
                  ">广告封禁3天</Button>


            </Col>
          </Row>


          <div v-if="oneItem.deleted_at != null "><strong>删除时间：</strong>{{ oneItem.deleted_at }}</div>


          <div>
            <strong>动态id:</strong>{{ oneItem.id }}
            <strong>板块:</strong>
            {{ getMomentSubjectName(oneItem.subject_id) }}-{{ oneItem.anonymous ? '匿名' : '公开' }}
          </div>


          <div>
            <span @click="openUserDetailsModel(oneItem.user.id)"><strong>用户:</strong>{{
                oneItem.user.nickname
              }}({{ oneItem.user.id }});</span>
            <strong>发布于:</strong>{{ oneItem.created_at }}
          </div>


          <br>
          <div>{{ oneItem.content }}</div>

          <div v-if="oneItem.images && oneItem.images.length>0">
            <h4>图片</h4>
            <img v-for="im in oneItem.images" :key="im.id" :src="im.url" alt="" width="300px">
          </div>
        </TabPane>
        <TabPane :label="tabCommentLabel" name="comments">
          <div v-if="oneItem.comments && oneItem.comments.length>0">
            <Timeline>
              <TimelineItem v-for="item in oneItem.comments" :key="item.id">
                <p class="time">
                  <Tag>{{ item.id }}</Tag>
                  <Tag color="primary"> {{ item.created_at }}</Tag>
                  <Tag v-show="undefined !== item.user">
                    <span @click="openUserDetailsModel(item.user.id)">{{ item.user.nickname }}</span>
                  </Tag>
                  <Tag v-if="undefined != item.object_user" color="primary">to</Tag>
                  <Tag v-if="undefined != item.object_user" @click="openUserDetailsModel(item.object_user.id)">
                    <span @click="openUserDetailsModel(item.object_user.id)">{{ item.object_user.nickname }}</span>
                  </Tag>
                  <Button v-if="item.deleted_at == null" type="error" size="small" icon="ios-trash"
                          @click="delComment(item.id)"></Button>
                  <Tag v-if="item.deleted_at != null" color="primary">删除时间{{ item.deleted_at }}</Tag>
                </p>
                <p class="content">{{ item.content }}</p>
              </TimelineItem>
            </Timeline>
            <br/>
          </div>

          <!--          <Row>
                      <Table border size="small" :columns="commentsColumns" :data="oneItem.comments">
                        <template slot-scope="{ row }" slot="user">
                             <span v-show="undefined!=row.user">
                                <span @click="openUserDetailsModel(row.user.id)">
                                  {{ undefined == row.user ? "" : row.user.nickname }}
                                </span>
                               ({{ undefined == row.user ? "" : row.user.id }})
                             </span>
                        </template>
                        <template slot-scope="{ row }" slot="object_user">
                             <span v-show="undefined!=row.object_user">
                                <span @click="openUserDetailsModel(row.object_user.id)">
                                  {{ undefined == row.object_user ? "" : row.object_user.nickname }}
                                </span>
                               ({{ undefined == row.object_user ? "" : row.object_user.id }})
                             </span>
                        </template>
                      </Table>
                    </Row>-->


        </TabPane>
        <TabPane :label="tabPraiseLabel" name="praises">
          <div v-if="oneItem.praise_users && oneItem.praise_users.length>0">
            <Avatar size="50" v-for="item in oneItem.praise_users" :key="item.id" :src="item.avatar"/>
          </div>
        </TabPane>
      </Tabs>


    </Modal>
  </div>
</template>

<script>
export default {
  name: "momentDetails",
  props: ['id', 'commentId'],

  watch: {
    id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getMomentDetails();
      }
    },
    commentId(newVal, oldVal) {
      console.log("评论")
      if (newVal !== oldVal) {
        this.getMomentDetailsByCommentId();
      }
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      oneItem: {},

      tabCommentLabel: (h) => {
        return h('div', [
          h('span', '评论列表'),
          h('Badge', {
            props: {
              count: undefined == this.oneItem.comments ? 0 : this.oneItem.comments.length
            }
          })
        ])
      },
      tabPraiseLabel: (h) => {
        return h('div', [
          h('span', '点赞列表'),
          h('Badge', {
            props: {
              count: undefined == this.oneItem.praise_users ? 0 : this.oneItem.praise_users.length
            }
          })
        ])
      },


      commentsColumns: [
        {title: 'id', width: 80, key: "id"},
        {title: '评论人', width: 160, slot: "user"},
        {title: '被评论', width: 160, slot: "object_user"},
        {title: '内容', width: 300, key: "content"},
        {title: '时间', width: 160, key: "created_at"},
      ],

      modelWidth: 700
    }
  },
  created() {
    if (window.screen.width <= 500) {
      this.modelWidth = "100%"
    }
    this.getMomentDetails();
  },
  methods: {
    getMomentDetails() {
      if (this.id === undefined || this.id <= 0) {
        return
      }
      this.oneItem = {};
      this.loading = true;
      this.show = true;
      this.$http.get("/moments/" + this.id).then(res => {
        this.oneItem = res.data.data;
        this.loading = false;
      });
    },

    getMomentDetailsByCommentId() {
      if (this.commentId === undefined || this.commentId <= 0) {
        return
      }
      this.oneItem = {};
      this.loading = true;
      this.show = true;
      this.$http.get("/moments/details", {params: {comment_id: this.commentId}}).then(res => {
        this.oneItem = res.data.data;
        this.loading = false;
      });
    },

    delItem() {
      this.$http.delete('/moments/' + this.oneItem.id).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.show = false
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },

    setTopStatus(momentID) {
      this.$http.patch('/moment/' + momentID + '/top_status').then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
          this.getMomentDetails();
          this.getMomentDetailsByCommentId();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },

    delComment(commentId) {
      this.$http.delete('/moments/comment/' + commentId).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.getMomentDetails();
          this.getMomentDetailsByCommentId();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },


    getGenderString(gender) {
      switch (gender) {
        case 1:
          return "男";
        case 2:
          return "女";
        default:
          return "未知";
      }
    },
    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    },
  }
}
</script>
