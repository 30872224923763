<template>
    <div>
        <Row>
            <h2>{{question_bank_name}}题目管理</h2>
        </Row>
        <br>
        <Row>
            <Col span="6">
                <Button type="primary" @click="editModal = true">导入</Button>
            </Col>
        </Row>
        <br>
        <Row>
            <Table border :loading="tableLoading" :columns="tableColumns" :data="tableData"></Table>
            <div style="margin: 10px;overflow: hidden">
                <div style="float: right;">
                    <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
                </div>
            </div>
        </Row>

        <Modal v-model="editModal" title="导入题库">
            <Row>
                <Upload
                        action="/backend/question-bank/import-topics"
                        multiple
                        type="drag"
                        :before-upload="handleUpload"
                        :on-success="uploadSuccess"
                        :on-error="uploadSuccess"
                        :data="{question_bank_id:question_bank_id}"
                >
                    <div style="padding: 20px 0">
                        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                        <p>Click or drag files here to upload</p>
                    </div>
                </Upload>
            </Row>
            <Row>

            </Row>

            <div slot="footer"></div>
        </Modal>
        <Modal v-model="importResponseModel" title="导入结果">
            <Row>
                导入文件共：{{importResponse.total_num}}行<br>
                其中，错误：{{importResponse.invalid_num}}行<br>
                错误详情如下:<br>
                <ul>
                    <li v-for="item in importResponse.invalid_lines" :key="item.row">
                        第{{item.row}}行:{{item.info}}
                    </li>
                </ul>
            </Row>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableLoading: true,
                tableColumns: [
                    {
                        title: '题库',
                        width: 120,
                        key: 'question_bank_id',
                        render: (h, params) => {
                            return h('span',params.row.question_bank.name);
                        }
                    },
                    {
                        title: '题目类型',
                        width: 120,
                        key: 'topic_type'
                    },
                    {
                        title: '题干',
                        key: 'topic_content'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 150,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    on: {
                                        click: () => {
                                            this.remove(params.index)
                                        }
                                    }
                                }, '删除')
                            ]);
                        }
                    }
                ],
                tableData: [],

                editModal: false,

                nowPage: 1,
                pageTotal: 0,


                importResponseModel: false,
                importResponse: {
                    total_num: 0,
                    invalid_num: 0,
                    invalid_lines: []
                },

                question_bank_id: 0,
                question_bank_name:''

            }
        },
        created() {
            if (!this.$route.params.question_bank_id){
                this.$router.push({
                    name: 'question-banks',
                })
            }


            this.question_bank_id = this.$route.params.question_bank_id;
            this.question_bank_name = this.$route.params.question_bank_name;


            this.getList();
        },
        methods: {
            getList() {
                this.tableLoading = true;
                var that = this;
                this.$http.get('/question-bank/topics',{params:{page:that.nowPage,question_bank_id:that.question_bank_id}}).then(function (response) {

                    that.tableLoading = false;

                    if (200 === response.data.code) {
                        that.tableData = response.data.data;
                        that.pageTotal = response.data.meta.pagination.total;
                        that.question_bank.question_bank_lists = response.data.meta.question_bank_lists;
                    }
                }, function () {
                    that.tableLoading = false;
                });
            },
            changePage(page) {
                this.nowPage = page;
                this.getList();
            },
            handleUpload() {
                if (this.question_bank_id <= 0) {
                    this.$Notice.error({
                        title: '请选择题库'
                    });
                    return false;
                }
                return true;
            },
            uploadSuccess(response) {
                console.log(response);
                if (200 === response.code) {
                    this.importResponse = response.data;
                    this.$Notice.success({
                        title: '操作成功',
                        desc: '导入成功'
                    });
                    this.getList();
                    this.editModal = false;
                    this.importResponseModel = true;
                } else {
                    this.$Notice.error({
                        title: '操作失败',
                        desc: response.msg
                    });
                }
            },
            remove(index) {
                var that = this;
                this.$http.delete('/admin/topics/' + this.tableData[index].id).then(function (response) {
                    console.log(response.data);
                    if (200 == response.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.getList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: response.data.msg
                        });
                    }
                }, function () {
                    that.tableLoading = true;
                });
            }
        }
    }
</script>
