<template>
    <div>
        <Row>
            <h2>工作台</h2>
        </Row>
        <br>

        <Row>
            <Button size="large" type="primary" icon="ios-trash" @click="cleanCache">清除缓存</Button>
        </Row>
    </div>
</template>
<script>
    export default {
        data(){
            return{

            }
        },
        created() {
        },
        methods:{
            cleanCache() {
                var that=this;
                this.$http.get('/system/clean_cache/').then(function(resdata) {
                    console.log(resdata);
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '操作成功'
                        });
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },
        }
    }
</script>