<template>
    <div class="tinymce-editor">
        <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick"></editor>
    </div>
</template>
<script>
    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver'
    import 'tinymce/skins/ui/oxide/skin.css'


    // 编辑器插件plugins
    // 更多插件参考：https://www.tiny.cloud/docs/plugins/
    import 'tinymce/plugins/image'// 插入上传图片插件
    import 'tinymce/plugins/media'// 插入视频插件
    import 'tinymce/plugins/table'// 插入表格插件
    import 'tinymce/plugins/lists'// 列表插件
    import 'tinymce/plugins/wordcount'// 字数统计插件


    import 'tinymce/plugins/code'
    export default {
        components: {
            Editor
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            plugins: {
                type: [String, Array],
                default: 'lists image media table wordcount code'
            },
            toolbar: {
                type: [String, Array],
                default: 'code undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
            }
        },
        data() {
            return {

                init: {
                    language_url: `/tinymce/langs/zh_CN.js`,
                    skin_url: `/tinymce/skins/ui/oxide`,
                    content_css: `/tinymce/skins/content/default/content.css`,
                    language: 'zh_CN',

                    height: 300,
                    plugins: this.plugins,
                    toolbar: this.toolbar,
                    branding: false,
                    menubar: false,

                    // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
                    // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
                    images_upload_handler: this.images_upload_handler
                },
                myValue: this.value
            }
        },
        mounted() {
            tinymce.init({})
        },
        methods: {

            // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
            // 需要什么事件可以自己增加
            onClick(e) {
                this.$emit('onClick', e, tinymce)
            },


            images_upload_handler(blobInfo, success, failure) {


                if (blobInfo.blob().size > 2097152) {
                    failure("文件体积过大");
                }
                if (blobInfo.blob().type === "image/jpeg" || blobInfo.blob().type === "image/png") {
                    let formData = new FormData();
                    formData.append("scene", "news");
                    formData.append("file", blobInfo.blob());
                    this.$http.post('/upload', formData).then(
                        function (response) {
                            console.log(response);
                            success(response.data.data.file_path);
                        },
                        function (response) {
                            console.log(response);
                            failure(response.data.msg)
                        }
                    );
                } else {
                    failure("图片格式错误");
                }
            }

        },
        watch: {
            value(newValue) {
                this.myValue = newValue
            },
            myValue(newValue) {
                this.$emit('input', newValue)
            }
        }
    }

</script>
