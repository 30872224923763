exports.install = (Vue) => {
    Vue.prototype.getMomentSubjectName = (o) => {
        switch (o) {
            case 0:
                return '校友圈';
            case 1:
                return "树洞";
            case 2:
                return '军训';
            case 3:
                return "二手信息";
            default:
                return "未知"
        }
    };
    Vue.prototype.getUserIdentityName = (identity) => {
        switch (identity) {
            case 0:
                return '游客';
            case 1:
                return '学生';
            case 2:
                return '老师';
            default:
                return '其他'
        }
    };
    Vue.prototype.getUserStateName = (identity) => {
        switch (identity) {
            case 0:
                return '未注册';
            case 1:
                return '正常';
            case -1:
                return '禁用';
            case -2:
                return '注销';
            default:
                return '其他'
        }
    };
};
