<template>
    <div class="body">
        <Row>
            <Col span="6">
                经度：{{record.latitude}}
            </Col>
            <Col span="6">
                纬度：{{record.longitude}}
            </Col>
            <Col span="6">
                评分：{{record.rating}}
            </Col>
            <Col span="6">
                人均消费：{{record.cost}}
            </Col>
            <Col span="24">
                简介：{{record.introduction}}
            </Col>
            <Col span="24">
                地址：{{record.address}}
            </Col>
        </Row>
    </div>
</template>

<script>
    export default {
        name: "ShopExpand",
        props: {
            record: {
                type: Object,
                default: () => {
                    return {
                        latitude: '',
                        longitude: '',
                        rating: 0,
                        cost: 0,
                        introduction: '',
                        address: ''
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .body {
        font-size: 20px;
    }
</style>