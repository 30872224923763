<template>
    <div>
        <Row>
            <h2>应用管理</h2>
        </Row>
        <br>
        <Row>

            <Col span="24">
                <Button type="primary" @click="openAddModel">新增</Button>

                <Input v-model="searchValue" clearable placeholder="请输入搜索内容" style="width:300px"></Input>
                <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
            </Col>
        </Row>

        <br>

        <Row>
            <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1"></Table>
        </Row>

      <div style="margin: 10px;overflow: hidden">
        <div style="float: left;">
          <Page :total="pageTotal" :page-size="10" show-elevator show-total @on-change="changePage"></Page>
        </div>
      </div>

        <Modal v-model="editModel" title="编辑应用" width="80%">
            <Form :model="item" :label-width="100">
                <Row>
                    <Col span="8">
                        <FormItem label="名称">
                            <Input v-model="item.name" placeholder=""></Input>
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="别名">
                            <Input v-model="item.alias" placeholder=""></Input>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="8">
                        <FormItem label="类别">
                            <Select v-model="item.application_type_id">
                                <Option v-for="type in types" :value="type.id" :key="type.id">{{ type.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span="8">
                        <FormItem label="排序">
                            <InputNumber v-model="item.sort" :min="0"></InputNumber>
                        </FormItem>
                    </Col>

                    <Col span="8">
                        <FormItem label="推荐">
                            <InputNumber v-model="item.recommend" :min="0"></InputNumber>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <FormItem label="简介">
                            <Input v-model="item.description" placeholder=""></Input>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <FormItem label="当前说明">
                            <Input v-model="item.information" placeholder=""></Input>
                        </FormItem>
                    </Col>
                </Row>


                <Row>
                    <FormItem label="平台">
                        <table>
                            <tr>
                                <th>平台</th>
                                <th>种类</th>
                                <th>状态</th>
                                <th>图标类型</th>
                                <th>图标</th>
                                <th>路径</th>
                            </tr>
                            <tr v-for="(platform,index) in item.platforms" :key="index">
                                <td>
                                    <Select v-model="platform.platform" style="width: 120px;">
                                        <Option value="android">android</Option>
                                        <Option value="ios">ios</Option>
                                        <Option value="web">web</Option>
                                        <Option value="mini">mini</Option>
                                    </Select>
                                </td>
                                <td>
                                    <Select v-model="platform.kind" style="width: 120px;">
                                        <Option :value="1">原生</Option>
                                        <Option :value="2">web</Option>
                                    </Select>
                                </td>
                                <td>
                                    <Select v-model="platform.status" style="width: 120px;">
                                        <Option :value="1">启用</Option>
                                        <Option :value="0">禁用</Option>
                                        <Option :value="-1">隐藏</Option>
                                    </Select>
                                </td>
                                <td>
                                    <Select v-model="platform.ico_type" style="width: 120px;">
                                        <Option :value="1">网络</Option>
                                        <Option :value="2">本地</Option>
                                    </Select>
                                </td>
                                <td>
                                    <Input v-model="platform.ico" placeholder=""></Input>
                                </td>
                                <td>
                                    <Input v-model="platform.path" placeholder=""></Input>
                                </td>
                                <td>
                                    <Button type="error" @click="subPlatform(index,platform.id)">删除</Button>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <Button type="success" @click="addPlatform" long>增加</Button>
                                </td>
                            </tr>
                        </table>
                    </FormItem>
                </Row>

                <FormItem>
                    <Button type="primary" @click="addItem">提交</Button>
                </FormItem>
            </Form>
            <div slot="footer"></div>
        </Modal>


    </div>
</template>


<script>
    export default {
        data() {
            return {
                tableLoading: true,//table加载中
                editModel: false,//
                nowPage: 1,//当前页
                pageTotal: 0,//总页数
                searchValue: '',//搜索值


                item: {},
                columns1: [
                    {title: '序号', align: 'center', width: 65, key: 'id'},

                    {title: '名称', align: 'center',width: 200, key: 'name'},
                    {title: '别名', align: 'center', width: 200,key: 'alias'},
                    {
                        title: '类型',
                      width: 120,
                        key: 'manager_id',
                        render: (h, params) => {
                            const row = params.row;
                            return h('span', row.type.name)
                        }
                    },
                    {
                        title: '平台',
                        width: 200,
                        render: (h, params) => {
                            const row = params.row;
                            let platforms = [];
                            row.platforms.forEach(platform => {

                                let tag_color = 'default';

                                switch (platform.status) {
                                    case -1:
                                        tag_color = 'error';
                                        break;
                                    case 0:
                                        tag_color = 'warning';
                                        break;
                                    case 1:
                                        tag_color = 'success';
                                        break;
                                }

                                platforms.push(h('Tag', {
                                    props: {
                                        color: tag_color
                                    }
                                }, platform.platform));
                            });
                            return h('div', platforms)
                        }
                    },
                    {title: '排序',width: 65, align: 'center', key: 'sort'},
                    {title: '推荐', width: 65,align: 'center', key: 'recommend'},
                    {
                        title: '操作',
                        key: 'action',
                        width: 200,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openEditItemModel(params.index)
                                        }
                                    }
                                }, '编辑'),

                              h('Poptip',{
                                props: {
                                  confirm: true,
                                  transfer: true,
                                  title: '你确定要删除吗？'
                                },
                                on: {
                                  'on-ok': () => {
                                    this.delItem(params.index)
                                  }
                                }
                              },[
                                h('Button', {
                                  props: {
                                    type: 'error',
                                    size: 'small'
                                  },
                                }, '删除')
                              ])
                            ]);
                        }
                    }
                ],
                data1: [],

                types: [],

            }
        },

        created() {
            this.getDataList();
            this.getTypes();
        },
        methods: {
            changePage(page) {
                this.nowPage = page;
                this.getDataList();
            },
            getTypes() {
                let that = this;
                this.$http.get("/applications/types").then(function (res) {
                    if (200 === res.data.code) {
                        that.types = res.data.data;
                    }
                });
            },
            getDataList() {
                var that = this;
                that.tableLoading = true;
                this.data1 = [];
                this.$http.get("/applications", {
                    params: {
                        page: that.nowPage,
                        word: that.searchValue
                    }
                }).then(function (res) {
                    that.tableLoading = false;
                    if (200 === res.data.code) {
                        that.data1 = res.data.data;
                        that.pageTotal = res.data.meta.pagination.total;
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: res.data.msg
                        });
                    }
                });
            },

            openAddModel() {
                this.item = {
                    application_type_id: 0,
                    name: "",
                    alias: "",
                    sort: 0,
                    recommend: 0,
                    description: '',
                    information: '',
                    platforms: [
                        {
                            platform: "android",
                            kind: 1,
                            status: 1,
                            ico: "",
                            ico_type: 1,
                            path: "",
                        }
                    ]
                };
                this.editModel = true;
            },
            openEditItemModel(index) {
                this.item = {};
                this.item = this.data1[index];
                this.editModel = true;
            },

            addItem() {
                var that = this;
                this.$http.post('/application', this.item).then(function (resdata) {
                    if (200 === resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '添加成功'
                        });
                        that.editModel = false;
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },


            delItem(index) {
                var that = this;
                this.$http.delete('/application/' + this.data1[index].id).then(function (resdata) {
                    if (200 == resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.editModel = false;
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            },


            changeStatus(index) {
                var that = this;
                this.$http.get('/versions/' + this.data1[index].id + '/status/').then(function (resdata) {
                    if (200 == resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '操作成功'
                        });
                        that.getDataList();
                    } else {
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            },


            async subPlatform(index, platformId) {
                try {
                    let res = await this.$http.delete(`/application/deletePlatform/${platformId}`)
                    let {msg, code} = res.data
                    if (code === 200) {
                        this.$Notice.success({
                            title: '操作成功'
                        });
                        this.item.platforms.splice(index, 1);
                    } else {
                        this.$Notice.error({
                            title: '操作失败',
                            desc: msg
                        });
                    }
                } catch (e) {
                  console.log(e)
                }
            },
            addPlatform() {
                this.item.platforms.push({
                    platform: "android",
                    kind: 1,
                    status: 1,
                    ico: "",
                    ico_type: 1,
                    path: "",
                });
            },


        }
    }
</script>
