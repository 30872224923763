<template>
    <div>
        <div style="margin-bottom: 10px;">

            <Row>
                <Col span="2">
                    <Button icon="md-add" type="primary" @click="handlerAdd">新增店铺</Button>
                </Col>
                <Col span="4">
                    <Select placeholder="请选择店铺状态" v-model="shopStatus">
                        <Option v-for="(item,key) in shopStatusArray" :value="item.value" :key="key">
                            {{item.title}}
                        </Option>
                    </Select>
                </Col>
                <Col span="2">
                    <Button type="success" style="margin-left:10px; " @click="init">查询</Button>
                </Col>
            </Row>
        </div>
        <Table :columns="columns" :data="data" border :loading="loading">
            <img :src="row.sign" alt="" slot="sign" slot-scope="{ row }" height="80" style="padding: 2px;">
            <template slot="description" slot-scope="{row}">
                <Button size="small" @click="handleShowDetailModal(row)" type="primary">点击查看</Button>
            </template>
            <template slot="action" slot-scope="{row}">
                <Button size="small" type="primary" @click="handlerEdit(row)">编辑</Button>
                <Button type="error" size="small" @click="handlerDelete(row)">删除</Button>
            </template>
            <template slot="status" slot-scope="{row }">
                <Tag color="success" v-if="row.status === 1">上架</Tag>
                <Tag color="error" v-else>下架</Tag>
            </template>
        </Table>
        <Page :current.sync="current" :total="total" style="text-align: right;margin-top: 10px;" :page-size="1"/>
        <shop-modal ref="shop" @complete="init" :total="total"/>
        <Modal title="图文详情" v-model="detailModal">
            <div v-html="detailHtml"></div>
        </Modal>
    </div>
</template>

<script>

    import ShopModal from "./components/ShopModal";
    import ShopExpand from "./components/ShopExpand";

    export default {
        name: "shop",
        components: {ShopModal},
        data() {
            return {
                detailModal: false,
                shopStatusArray: [
                    {
                        title: '上架',
                        value: 1
                    },
                    {
                        title: '下架',
                        value: 0
                    },
                ],
                shopStatus: 1,
                current: 1,
                total: 0,
                loading: true,
                detailHtml: '',
                columns: [
                    {
                        type: 'expand',
                        width: 50,
                        render: (h, params) => {
                            return h(ShopExpand, {
                                props: {
                                    record: params.row
                                }
                            })
                        }
                    },
                    {
                        title: "商铺名称",
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: "商铺电话",
                        key: 'telephone',
                        align: 'center'
                    },
                    {
                        title: "商铺logo",
                        slot: 'sign',
                        align: 'center'
                    },
                    {
                        title: "图文介绍",
                        slot: 'description',
                        align: 'center'
                    },
                    {
                        title: "省代码",
                        key: 'province_code',
                        align: 'center'
                    },
                    {
                        title: "市代码",
                        key: 'city_code',
                        align: 'center'
                    },
                    {
                        title: "区代码",
                        key: 'district_code',
                        align: 'center'
                    },
                    {
                        title: "商圈代码",
                        key: 'business_code',
                        align: 'center'
                    },
                    {
                        title: "地区",
                        key: 'area',
                        align: 'center'
                    },
                    {
                        title: "人均消费",
                        key: 'cost',
                        align: 'center'
                    },
                    {
                        title: '商铺状态',
                        slot: 'status',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 130,
                        align: 'center'
                    }
                ],
                data: []
            }
        },
        methods: {
            async init(page = 1) {
                this.loading = true
                try {
                    let res = await this.$http.get(`/shop/show/${this.shopStatus}/?page=${page}`)
                    this.data = res.data.data
                    this.total = res.data.meta.pagination.total / 10
                    this.loading = false
                } catch (e) {
                    console.log(e)
                }

            },
            handlerEdit(record) {
                this.$refs.shop.edit(record)
            },
            handlerAdd() {
                this.$refs.shop.add()
            },
            handlerDelete(row) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '你确定要删除嘛?',
                    onOk: () => {
                        this.$http.get(`/shop/del/${row.id}`)
                    }
                })
            },
            handleShowDetailModal(row) {
                this.detailModal = true
                this.detailHtml = row.description
            }
        },
        watch: {
            'current': function (newValue) {
                this.init(newValue)
            }
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>