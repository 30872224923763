<template>
  <div>
    <Row>
      <h2>校友圈管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入用户姓名或手机" style="width:200px"></Input>

        <Input v-model="search_user_id" clearable placeholder="用户id" style="width:80px"></Input>

        <Select v-model="search_subject_ids" clearable multiple placeholder="话题" style="width:100px">
          <Option :value="0" :key="0">校友圈</Option>
          <Option :value="1" :key="1">树洞</Option>
          <Option :value="2" :key="2">军训</Option>
          <Option :value="3" :key="3">交易</Option>
        </Select>

        <Select v-model="search_deleted" clearable placeholder="删除状态" style="width:100px">
          <Option :value="0" :key="0">全部</Option>
          <Option :value="1" :key="1">未删除</Option>
          <Option :value="-1" :key="-1">已删除</Option>
        </Select>

        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>

    <br>

    <Row>
      <List item-layout="vertical">
        <ListItem :style="{width: '100%'}" v-for="moment in data1" :key="moment.id">
          <ListItemMeta :avatar="moment.user.avatar"
                        :title="moment.user.nickname+':'+moment.user_id"
                        :description="getMomentSubjectName(moment.subject_id)+':'+moment.anonymous===1 ? '匿名' : '公开'"/>
          <template slot="action">
            <li>
              <Icon type="ios-chatbubbles-outline"/>
              {{ moment.comments_count }}
            </li>
            <li>
              <Icon type="ios-chatbubbles-outline"/>
              {{ moment.comments_count }}
            </li>
            <li>
              <Button label="small" v-show="moment.subject_id==3" type="info" @click="setSubject(moment.id, 0)">校友圈
              </Button>
              <Button label="small" v-show="moment.subject_id!=3" type="info" @click="setSubject(moment.id, 3)">交易
              </Button>
            </li>
            <li>
              <Button label="small" v-show="!moment.deleted_at" type="error" @click="delItem(moment.id)">删除</Button>
            </li>
          </template>
          {{ moment.content }}
          <Carousel loop>
            <CarouselItem v-for="item in moment.images" :key="item.id">
              <img :src="item.url" height="300px" width="100%">
            </CarouselItem>
          </Carousel>
          {{ moment.created_at }}
        </ListItem>
      </List>
    </Row>
    <div style="margin: 10px;overflow: hidden">
      <div style="float: left;">
        <Page :total="pageTotal" simple @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      search_user_id: '',
      search_subject_id: 0,
      search_subject_ids: [0, 1],
      search_deleted: 1,
      search_status: '',

      tableLoading: true,//table加载中

      oneItem: {},
      detailsModel: false,//用户详情弹窗


      data1: []
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      var that = this;
      that.tableLoading = true;
      this.data1 = [];
      this.$http.get("/moments", {
        params: {
          page: that.nowPage, word: that.searchValue,
          user_id: that.search_user_id,
          subject_ids: that.search_subject_ids,
          deleted: that.search_deleted,
          status: that.search_status
        }
      }).then(function (res) {
        console.log(res.data);
        that.data1 = res.data.data;
        that.pageTotal = res.data.meta.pagination.total;
        that.tableLoading = false;
      });
    },
    openDetailsModel(index) {
      this.oneItem = {};
      this.oneItem = this.data1[index];
      this.detailsModel = true;
    },
    setSubject(momentId, subject_id) {
      this.$http.patch('/moment/' + momentId + '/subject/' + subject_id).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    setTopStatus(index) {
      this.$http.patch('/moment/' + this.data1[index].id + '/top_status').then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '操作成功'
          });
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    delItem(momentId) {
      var that = this;
      this.$http.delete('/moments/' + momentId).then(function (resdata) {
        if (200 == resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.addStudentModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },

    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    }
  }
}
</script>
