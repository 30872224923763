<template>
  <div>
    <Row>
      <h2>新闻管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Button type="primary" @click="openAddItemModel">新增</Button>
        <Select clearable v-model="site_id" style="width:200px">
          <Option v-for="site in sites" :key="site.id" :value="site.id">{{ site.name }}</Option>
        </Select>
        <Input v-model="searchValue" clearable placeholder="请输入搜索标题" style="width:300px"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1"></Table>

      <div style="margin: 10px;overflow: hidden">

        <div style="float: right;">
          <Page :total="pageTotal" :page-size="20" show-elevator show-total @on-change="changePage"></Page>
        </div>
      </div>
    </Row>

    <Modal v-model="editModel" title="编辑新闻" width="80%">
      <Form :model="item" :label-width="60">
        <Row>
          <Col span="24">
            <FormItem label="标题">
              <Input v-model="item.title" placeholder=""></Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="8">
            <FormItem label="站点">
              <Select v-model="item.site_id">
                <Option v-for="site in sites" :key="site.id" :value="site.id">{{ site.name }}</Option>
              </Select>
            </FormItem>
          </Col>

          <Col span="4">
            <FormItem label="类型">
              <Select v-model="item.type">
                <Option :value="1">站群中新闻</Option>
                <Option :value="2">校友圈广告</Option>
              </Select>
            </FormItem>
          </Col>

          <Col span="4">
            <FormItem label="状态">
              <Select v-model="item.status" style="width: 70px;">
                <Option :value="0">隐藏</Option>
                <Option :value="1">公开</Option>
              </Select>
            </FormItem>
          </Col>

          <Col span="4">
            <FormItem label="点击量">
              <InputNumber v-model="item.hits" :min="0"></InputNumber>
            </FormItem>
          </Col>

          <Col span="4">
            <FormItem label="排序">
              <InputNumber v-model="item.rank" :min="0"></InputNumber>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="24">
            <FormItem label="原网址">
              <Input v-model="item.url" placeholder=""></Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="12">
            <FormItem label="发布时间">
              <DatePicker v-model="item.created_at" type="datetime"
                          format="yyyy-MM-dd HH:mm:ss"
                          @on-change="dataChange"
                          placeholder="请选择发布时间" style="width: 300px"></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="标签">
              <Select v-model="item.tags" multiple>
                <Option v-for="item in tags" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="24">
            <FormItem label="图片">
              <div class="demo-upload-list" v-for="image in item.images" :key="image">
                <img :src="image">
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" @click.native="handleView(image)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemove(image)"></Icon>
                </div>
              </div>
              <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :on-success="handleSuccess"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize"
                  multiple
                  type="drag"
                  action="/api/backend/upload"
                  style="display: inline-block;width:58px;">
                <div style="width: 58px;height:58px;line-height: 58px;">
                  <Icon type="ios-camera" size="20"></Icon>
                </div>
              </Upload>
              <Modal title="View Image" v-model="visible">
                <img :src="ViewImageUrl" v-if="visible" style="width: 100%">
                <div slot="footer"></div>
              </Modal>
            </FormItem>
          </Col>
        </Row>


        <Row>
          <Col span="24">
            <FormItem label="内容">
              <editor v-if="editModel" v-model="item.content"></editor>
            </FormItem>
          </Col>
        </Row>

        <FormItem>
          <Button type="primary" @click="addItem">提交</Button>
        </FormItem>
      </Form>
      <div slot="footer"></div>
    </Modal>


    <Modal v-model="detailsModel" title="文章详情">
      <div class="details" v-html="item.content"></div>

      <div slot="footer"></div>
    </Modal>


  </div>
</template>


<script>
import editor from "../../components/editor";

export default {
  data() {
    return {
      ViewImageUrl: "",
      visible: false,


      tableLoading: true,//table加载中
      editModel: false,//增加学生弹窗
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      site_id: 0,//状态
      searchValue: '',//搜索值

      detailsModel: false,

      sites: [],
      tags: [],

      item: {
        id: "",
        type: 1,
        site_id: "",
        site_name: "",
        url: "",
        title: "",
        videos: [],
        images: [],
        files: [],
        tags: [],
        content: "",
        hits: 0,
        rank: 0,
        status: 1,
        created_at: "",
        updated_at: "",
      },

      columns1: [
        // {title: '序号',align: 'center',width:65, key: 'id'},
        {
          title: '标题', key: 'title',
          render: (h, params) => {
            const row = params.row;
            return h('a', {
              attrs: {
                href: row.url,
                target: '_blank'
              }
            }, row.title)
          }
        },
        {title: '站点', align: 'center', width: 160, key: 'site_name'},
        {
          title: '标签',
          render: (h, params) => {
            const row = params.row;
            let tags = [];
            row.tags.forEach(tag => {
              tags.push(h('Tag', tag.name));
            });
            return h('div', tags)
          }
        },
        {title: '点击', align: 'center', width: 70, key: 'hits'},
        {title: '排序', align: 'center', width: 70, key: 'rank'},
        {title: '发布时间', align: 'center', width: 160, key: 'created_at'},
        {
          title: '状态',
          width: 80,
          key: 'status',
          align: 'center',
          render: (h, params) => {
            const row = params.row;
            var divButton = [];
            switch (row.status) {
              case 0:
                divButton = [
                  h('Button', {
                    props: {
                      type: 'error',
                      size: 'small'
                    },
                    on: {
                      click: () => {
                        this.changeStatus(params.index)
                      }
                    }
                  }, '隐藏')
                ];
                break;
              case 1:
                divButton = [
                  h('Button', {
                    props: {
                      type: 'success',
                      size: 'small'
                    },
                    on: {
                      click: () => {
                        this.changeStatus(params.index)
                      }
                    }
                  }, '公开')
                ];
                break;
              default:
                divButton = [h('span', '未知')];
            }
            return h('div', divButton)
          }
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openDetailsModel(params.index)
                  }
                }
              }, '详情'),

              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.openEditItemModel(params.index)
                  }
                }
              }, '编辑'),

              h('Poptip', {
                props: {
                  confirm: true,
                  transfer: true,
                  title: '你确定要删除吗？'
                },
                on: {
                  'on-ok': () => {
                    this.delItem(params.index)
                  }
                }
              }, [
                h('Button', {
                  props: {
                    type: 'error',
                    size: 'small'
                  },
                }, '删除')
              ])
            ]);
          }
        }
      ],
      data1: []
    }
  },
  created() {
    this.getDataList();
    this.getTags();
  },
  components: {
    editor: editor
  },
  methods: {
    handleView(url) {
      this.ViewImageUrl = url;
      this.visible = true;
    },
    handleRemove(file) {
      this.item.images.splice(this.item.images.indexOf(file), 1);
    },
    handleSuccess(res, file) {
      file.url = res.data.file_path;
      file.name = res.data.file_name;

      this.item.images.push(file.url);
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: 'The file format is incorrect',
        desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: 'Exceeding file size limit',
        desc: 'File  ' + file.name + ' is too large, no more than 2M.'
      });
    },


    dataChange(data) {
      this.item.created_at = data;
    },
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getTags() {
      var that = this;
      this.$http.get("/articles/tags").then(function (res) {
        if (200 === res.data.code) {
          that.tags = res.data.data;
        }
      });
    },
    getDataList() {
      this.tableLoading = true;
      this.data1 = [];
      this.$http.get("/articles", {
        params: {
          page: this.nowPage,
          word: this.searchValue,
          site_id: this.site_id
        }
      }).then((res) => {
        this.tableLoading=false;
        if (200 === res.data.code) {
          this.data1 = res.data.data;
          this.pageTotal = res.data.meta.pagination.total;
          this.sites = res.data.meta.sites;
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },
    addItem() {
      var that = this;
      this.$http.post('/articles', this.item).then(function (resdata) {
        if (200 === resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          that.editModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      })
    },
    openAddItemModel() {
      this.item = {
        id: "",
        type: 1,
        site_id: "",
        site_name: "",
        url: "",
        title: "",
        videos: [],
        images: [],
        files: [],
        tags: [],
        content: "",
        hits: 0,
        rank: 0,
        status: 1,
        created_at: "",
        updated_at: "",
      };
      this.editModel = true;
    },
    openEditItemModel(index) {
      this.item = {
        id: "",
        type: 1,
        site_id: "",
        site_name: "",
        url: "",
        title: "",
        videos: [],
        images: [],
        files: [],
        tags: [],
        content: "",
        hits: 0,
        rank: 0,
        status: 1,
        created_at: "",
        updated_at: "",
      };
      let article = this.data1[index];
      let tags = [];
      article.tags.forEach(tag => {
        tags.push(tag.id);
      });
      article.tags = tags;
      this.item = article;
      this.editModel = true;
    },
    openDetailsModel(index) {
      this.item = {
        id: "",
        type: 1,
        site_id: "",
        site_name: "",
        url: "",
        title: "",
        videos: [],
        images: [],
        files: [],
        content: "",
        hits: 0,
        rank: 0,
        status: 1,
        created_at: "",
        updated_at: "",
      };
      this.item = this.data1[index];
      this.detailsModel = true;
    },
    delItem(index) {
      var that = this;
      this.$http.delete('/articles/' + this.data1[index].id).then(function (resdata) {
        if (200 == resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.editModel = false;
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    changeStatus(index) {
      var that = this;
      this.$http.get('/articles/' + this.data1[index].id + '/status/').then(function (resdata) {
        if (200 == resdata.data.code) {
          that.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          that.getDataList();
        } else {
          that.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    }
  }
}
</script>


<style>

.details img {
  width: 100%;
}

.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
