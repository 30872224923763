<style scoped>
.login {
  text-align: center;
  width: 300px;
  height: 300px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

</style>
<template>
  <div>
    <div class="login">
        <div style="width: 300px;">
          <h2>后台管理系统·账户登录</h2>
          <br>
          <div class="ivu-form-item">
            <Input v-model="username" prefix="ios-contact" placeholder="请输入账号" style="width: 100%"/>
          </div>
          <div class="ivu-form-item">
            <Input v-model="password" type="password" password prefix="md-lock" placeholder="请输入密码"
                   @keyup.enter.native="login"
                   style="width: 100%"/>
          </div>
          <br>
          <div class="ivu-form-item">
            <Button type="primary" @click="login" long>登录</Button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: ""
    }
  },
  created() {
  },
  methods: {

    login() {
      this.$http.post('/login', {
        number: this.username,
        password: this.password
      }).then((res) => {
        console.log(res);
        if (200 === res.data.code) {
          // location.href = '/backend/';
          localStorage.setItem("token", res.data.data);
          this.$router.push('/');
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      })
    }
  }
}
</script>
