<template>
  <div>
    <Row>
      <h2>反馈管理</h2>
    </Row>
    <br>
    <Row>
      <Col span="24">
        <Input v-model="searchValue" clearable placeholder="请输入用户姓名或手机" style="width:300px"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
      </Col>
    </Row>

    <br>

    <Row>
      <Table border size="small" :loading="tableLoading" :columns="columns1" :data="data1">
        <template slot-scope="{ row }" slot="user">
          <span @click="openUserDetailsModel(row.user.id)">{{ row.user.nickname }}({{ row.user.id }})</span>
        </template>
        <template slot-scope="{ row }" slot="source">
          {{ getSourceText(row.source) }}
          <Tag color="default">{{ row.client_version }}</Tag>
        </template>

        <template slot-scope="{ row }" slot="device">
          {{ row.device_model }}
          <Tag color="default">{{ row.system_version }}</Tag>
        </template>

        <template slot-scope="{ row }" slot="action">
          <Button type="primary" size="small" @click="openDetailsModel(row)" style="margin-right: 5px;">详情</Button>
          <Poptip title="你确定要删除这条反馈吗？" @on-ok="delItem(row.id)" confirm transfer>
            <Button type="error" size="small" style="margin-right: 5px;">删除</Button>
          </Poptip>
        </template>
      </Table>
    </Row>

    <div style="margin: 10px;overflow: hidden">
      <div style="float: left;">
        <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
      </div>
    </div>


    <Modal v-model="detailsModel" width="60%" title="反馈详情">
      <h2>反馈内容</h2>
      <p>{{ oneItem.content }}</p>


      <div v-if="oneItem.reply_content && oneItem.reply_content.length>0">
        <h2>回复内容</h2>
        <div>{{oneItem.reply_content}}</div>
      </div>
      <div v-else>
        <h2>回复内容</h2>
        <div>
          <Input type="textarea" :rows="5" v-model="reply_content"></Input>
        </div>
        <br>
        <div>
          <Button type="primary" @click="reply">回复</Button>
        </div>

        <div>
          <h3>常用语</h3>
          <Row>
            <ul>
              <li v-for="(word,index) in phrases" :key="index"
                  @click="reply_content=reply_content+word">{{ word }}
              </li>
            </ul>
          </Row>
        </div>
      </div>


      <h2>图片</h2>
      <div v-if="oneItem.files">
        <img v-for="item in oneItem.files" :key="item" :src="item" width="300px">
      </div>

      <div slot="footer"></div>
    </Modal>


  </div>
</template>
<script>

export default {
  data() {
    return {
      nowPage: 1,//当前页
      pageTotal: 0,//总页数
      searchValue: '',//搜索值
      tableLoading: true,//table加载中

      reply_content:"",

      oneItem: {},
      detailsModel: false,//用户详情弹窗



      phrases: [
        "同学你好:我们没有很清楚的理解到你当前遇到的问题，请加入我们的qq群（512219741）具体反馈一下呢",
        "同学你好:请加入我们的qq群（512219741）具体反馈一下呢",
        "同学你好:课表时间会在开学后自动同步为正确，若有疑问，请加入我们的qq群（512219741）具体反馈下",
        "已经为你解除封禁了。当前账号已经被列入重点关注对象，请勿利用校友圈进行任何广告、兼职等商业宣传行为，否则账号将会限制。",
        "绑定教务系统后在课表右上角选择(刷新课表/导入课表)进行课表的更新。安卓显示未灰色为本周不上课程、ios如果本周显示为空白可以在顶部切换周次来查看",
        "感谢你的建议，这个功能我们正在研究开发中，敬请期待",
        "请尝试登录教务系统官网（http://61.139.105.138/）验证密码是否正确，若密码仍然错误，请带上身份证或者学生证去所在学院的教学办，联系那里的老师为你重置密码",
        "请右上角手动切换宜宾校区时间",
      ],

      columns1: [
        {title: 'id', width: 70, key: 'id'},
        {title: '用户', width: 180, slot: 'user'},
        {title: '来源', width: 140, slot: 'source'},
        {title: '设备信息', width: 190, slot: 'device'},
        {title: '内容', width: 200, key: 'content'},
        {title: '反馈时间', width: 150, key: 'created_at'},
        {
          title: '回复', width: 94, key: 'reply_content',
          render: (h, params) => {
            if (params.row.reply_content) {
              return h('tag', {props: {color: "success"}}, "已回复")
            } else {
              return h('tag', {props: {color: "primary"}}, "未回复")
            }
          }
        },
        {title: '操作', key: 'action', width: 136, align: 'center', slot: 'action'}
      ],
      data1: []
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    changePage(page) {
      this.nowPage = page;
      this.getDataList();
    },
    getDataList() {
      var that = this;
      that.tableLoading = true;
      this.data1 = [];
      this.$http.get("/feedbacks", {params: {page: that.nowPage, word: that.searchValue}}).then(function (res) {
        that.data1 = res.data.data;
        that.pageTotal = res.data.meta.pagination.total;
        that.tableLoading = false;
      });
    },
    openDetailsModel(row) {
      this.oneItem = {};
      this.oneItem = row;
      this.detailsModel = true;
    },
    reply() {
      this.$http.post('/feedback', {
        id: this.oneItem.id,
        reply_content: this.reply_content
      }).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          this.reply_content = "";
          this.detailsModel = false;
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
        this.detailsModel = false;
      })
    },
    delItem(id) {
      this.$http.delete(`/feedbacks/${id}`).then((resdata) => {
        if (200 === resdata.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.detailsModel = false;
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: resdata.data.msg
          });
        }
      });
    },
    getSourceText(source) {
      switch (source) {
        case 0:
          return '未知';
        case 1:
          return 'Android';
        case 2:
          return 'IOS';
        case 3:
          return 'mini';
        case 4:
          return 'web';
        default:
          return '未知'
      }
    },
    openUserDetailsModel(id){
      this.$userDetails({id:id})
    }
  }
}
</script>
