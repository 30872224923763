<template>
<div>
<Row>
    <h2>管理员管理</h2>
</Row>
<br>
<Row>
    <Col span="24">
        <Button type="primary" @click="openAddStudentModel">新增</Button>


        <Input v-model="searchValue" clearable placeholder="请输入管理员工号或姓名" style="width:300px"></Input>
        <Button shape="circle" icon="ios-search" @click="getDataList"></Button>
    </Col>
</Row>

<br>

<Row>
    <Table border size="small" ref="selection" :loading="tableLoading" :columns="columns1" :data="data1" @on-selection-change="selectionChange"></Table>
    <div style="margin: 10px;overflow: hidden">
        <div style="float: left;">
            <Button type="error" @click="delByIdArray">删除选中项</Button>
        </div>
        <div style="float: right;">
            <Page :total="pageTotal" show-elevator show-total @on-change="changePage"></Page>
        </div>
    </div>
</Row>


<Modal v-model="addStudentModel" title="新增编辑管理员" @on-ok="addStudent">
    <Form :model="oneStudent" :label-width="80">


        <Row>
            <Col span="12">
                <FormItem label="工号">
                    <Input v-model="oneStudent.number" placeholder=""></Input>
                </FormItem>
            </Col>
            <Col span="12">
                <FormItem label="姓名">
                    <Input v-model="oneStudent.name" placeholder=""></Input>
                </FormItem>
            </Col>
        </Row>
        
        <FormItem label="密码">
            <Input v-model="oneStudent.password" placeholder="">
                <Icon type="md-lock" slot="prepend"></Icon>
            </Input>
        </FormItem>

        <FormItem label="用户组">
            <CheckboxGroup v-model="oneStudent.roleId">
                <Checkbox v-for="item in roleLists" :label="item.id" :key="item.id">{{item.name}}</Checkbox>
            </CheckboxGroup>
        </FormItem>

        <FormItem>
            <Button type="primary" :loading="saveTeacherButton" @click="addStudent">提交</Button>
        </FormItem>
    </Form>
    <div slot="footer"></div>
</Modal>


</div>
</template>
<script>
    export default {
        data(){
            return {
                selection:[],//多选，以选中的项
                nowPage:1,//当前页
                pageTotal:0,//总页数
                searchValue:'',//搜索值
                tableLoading:true,//table加载中

                oneStudent:{},//新增、编辑老师容器
                addStudentModel:false,//增加老师弹窗
                saveTeacherButton:false,//保存老师按钮loading

                
                roleLists:[],

                columns1: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {title: '工号',key: 'number'},
                    {title: '姓名',key: 'name'},
                    {
                        title: '用户组',
                        render: (h, params) => {
                            let name = '';
                            params.row.roles.forEach(v=>{
                                name+=v.name+";";
                            });
                            return h('div', name)
                        }
                    },
                    {
                        title: '状态',
                        width: 100,
                        key: 'status',
                        render: (h, params) => {
                            const row = params.row;
                            const color = row.status === false ? 'warning' :  'success';
                            const text = row.status === false ? '禁用' : '启用';
                            return h('Button', {
                                    props: {
                                        type: color,
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.changeStudentStaus(params.index)
                                        }
                                    }
                                }, text)
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 200,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [

                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.openEditStudentModel(params.index)
                                        }
                                    }
                                }, '编辑'),

                                h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                    this.delStudent(params.index)
                                }
                                }
                                }, '删除')
                            ]);
                        }
                    }
                ],
                data1: []
            }
        },
        created() {
            this.getDataList();
            this.getRoles();
        },
        methods:{
            selectionChange(selection) {
                this.selection=selection;
            },
            changePage(page) {
                this.nowPage=page;
                this.getDataList();
            },
            chooseChange() {
                this.getDataList();
            },
            getDataList(){
                var that=this;
                that.tableLoading=true;
                this.data1=[];
                this.$http.get("/managers",{params:{page:that.nowPage,word:that.searchValue}}).then(function(res){
                    console.log(res.data);
                    that.data1=res.data.data;
                    that.pageTotal=res.data.meta.pagination.total;
                    that.tableLoading=false;
                });
            },
            getRoles(){
                var that=this;
                this.roles=[];
                this.$http.get("/roles",{params:{page:that.nowPage,word:that.searchValue}}).then(function(res){
                    console.log(res.data);
                    that.roleLists=res.data.data;
                });
            },
            addStudent() {
                this.saveTeacherButton = true;
                var that=this;
                var url = "";
                if (this.oneStudent.id) {
                    url = '/managers/'+this.oneStudent.id;
                }else{
                    url = '/managers';
                }
                this.$http.post(url,that.oneStudent).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '添加成功'
                        });
                        that.addStudentModel=false;
                        that.getDataList();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                    that.saveTeacherButton =false;
                })
            },
            openAddStudentModel() {
                this.oneStudent={};
                this.addStudentModel=true;
            },
            openEditStudentModel(index) {
                this.oneStudent={};
                this.oneStudent=this.data1[index];
                this.oneStudent.password='';
                this.addStudentModel=true;
            },
            delStudent(index) {
                var that=this;
                this.$http.delete('/managers/'+this.data1[index].id).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.addStudentModel=false;
                        that.getDataList();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                });
            },
            changeStudentStaus(index) {
                var that =this;
                this.$http.get('/managers/'+this.data1[index].id+'/status').then(function(resdata) {
                    console.log(resdata);
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '更改成功'
                        });
                        that.getDataList();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            },
            delByIdArray() {
                var that =this;
                let ids=[];
                for (var i = this.selection.length - 1; i >= 0; i--) {
                    ids.push(this.selection[i].id);
                }
                if (ids.length<=0) {
                    return true;
                }
                this.$http.post('/managers/delByIdArray',{ids}).then(function(resdata) {
                    if (200==resdata.data.code) {
                        that.$Notice.success({
                            title: '操作成功',
                            desc: '删除成功'
                        });
                        that.addStudentModel=false;
                        that.getDataList();
                    }else{
                        that.$Notice.error({
                            title: '操作失败',
                            desc: resdata.data.msg
                        });
                    }
                })
            }
            
        }
    }
</script>
