<template>
  <div>
    <Modal v-model="show" :width="modelWidth"
           draggable
           sticky
           reset-drag-position
           :mask="false"
           :footer-hide="true">
      <p slot="header">
        <span>用户详情</span>-ID:{{ user.id }}-昵称:{{ user.nickname }}
      </p>

      <Spin size="large" fix v-if="loading"></Spin>
      <Row type="flex" justify="center" align="middle" class="code-row-bg">
        <Col :xs="24" :sm="24" :md="24" :lg="24">
          <Tabs :value="tabName" @on-click="handelTabClick" style="max-height:550px;overflow:auto;">
            <TabPane label="基本信息" name="details">
              <Row type="flex" justify="center" align="middle" class="code-row-bg">
                <Col :xs="24" :sm="24" :md="18" :lg="18">
                  <Card>
                    <div style="text-align:center">
                      <Row>
                        <Col :xs="24" :sm="24" :md="12" :lg="12">
                          <div>
                            <Avatar v-show="user.avatar" size="80" :src="user.avatar"/>
                          </div>
                        </Col>
                        <Col :xs="24" :sm="24" :md="12" :lg="12">
                          <div style="text-align:left">
                            <strong>ID:</strong>{{ user.id }}<br/>
                            <strong>昵称:</strong>{{ user.nickname }}<br/>
                            <strong>手机号:</strong>{{ user.phone }}<br/>
                            <strong>状态:</strong>{{ getUserStateName(user.status) }}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row :gutter="16">
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>真实姓名:</strong>{{ user.name }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>学号或工号:</strong>{{ user.number }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>身份:</strong>{{ getUserIdentityName(user.identity) }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>性别:</strong>{{ getGenderString(user.gender) }}</div>
                </Col>
              </Row>
              <Row :gutter="16">
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>学校:</strong>{{ user.school }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>学院:</strong>{{ user.college }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>专业年级:</strong>{{ user.major }}-{{ user.grade }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>班级:</strong>{{ user.class_name }}</div>
                </Col>
              </Row>
              <Row :gutter="16">
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>生日:</strong>{{ user.birthday }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>年龄:</strong>{{ user.age }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>星座:</strong>{{ user.constellation }}</div>
                </Col>
                <Col :xs="24" :sm="24" :md="6" :lg="6">
                  <div><strong>积分:</strong>{{ user.integral }}</div>
                </Col>
              </Row>
              <Row v-show="user.introduction" :gutter="16">
                <Col :xs="24" :sm="24" :md="18" :lg="18">
                  <div><strong>个性签名:</strong>{{ user.introduction }}</div>
                </Col>
              </Row>

              <Divider>用户操作</Divider>

              <Row :gutter="20">
                <Col :xs="24" :sm="24" :md="12" :lg="12">
                  <h2>发消息</h2>
                  <Button type="warning" size="small" @click="
                    $api.UserSendMessage(user.id,'【违规提醒】同学你好:请将交易类信息发布到[应用]标签下的[交易]模块中。若再违反此规则，平台将采取相应措施对账号进行禁言处理')
                  ">交易警告</Button>

                  <Button style="margin-left: 5px" type="warning" size="small" @click="
                    $api.UserSendMessage(user.id,'【举报反馈】同学你好:感谢你的举报，我们已经对该内容和该用户做出了一定的处理。')
                  ">举报反馈</Button>

                  <h3>发送消息</h3>
                  <Input type="textarea" :rows="5" v-model="send_message_content" placeholder=""></Input>
                  <h3>常用语</h3>
                  <ol>
                    <li v-for="(word,index) in phrases" :key="index"
                        @click="send_message_content=send_message_content+word">{{ word }}
                    </li>
                  </ol>
                  <Button type="primary" @click="$api.UserSendMessage(user.id,send_message_content)">发送</Button>
                </Col>
                <Col :xs="24" :sm="24" :md="12" :lg="12">
                  <h2>禁言</h2>
                  <Form :model="banItem" :label-width="60">
                    <Row>
                      <Col span="24">
                        <FormItem label="目标">
                          <CheckboxGroup v-model="banItem.targets">
                            <Checkbox :label="1">校友圈</Checkbox>
                            <Checkbox :label="2">评论</Checkbox>
                            <Checkbox :label="3">私聊</Checkbox>
                          </CheckboxGroup>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span="24">
                        <FormItem label="时间">
                          <DatePicker type="datetime"
                                      @on-change="chooseBanDataChange"
                                      :options="addBanChooseDateOptions"
                                      :value="this.banItem.end_at"
                                      placeholder="选择结束时间" style="width: 320px"></DatePicker>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span="24">
                        <FormItem label="原因">
                          <Select @on-change="addBanReasonChooseChange">
                            <Option value="发布言语人身攻击或骂人等不当言论">骂人、人身攻击</Option>
                            <Option value="未准守平台要求将广告、兼职等交易内容发布到交易区的规定">发布广告</Option>
                          </Select>
                          <Input v-model="banItem.reason" placeholder=""></Input>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span="24">
                        <FormItem label="描述"><Input v-model="banItem.desc" placeholder=""></Input></FormItem>
                      </Col>
                    </Row>
                    <FormItem>
                      <Button type="primary" @click="addBan">提交</Button>
                    </FormItem>
                  </Form>
                </Col>
              </Row>

            </TabPane>

            <TabPane label="动态信息" name="moments">
              <Row>
                <Table border size="small" :columns="momentColumns" :data="moments">
                </Table>
              </Row>
              <div style="margin: 10px;overflow: hidden">
                <div style="float: left;">
                  <Page :total="momentPageTotal" :page-size="20" show-elevator show-total
                        @on-change="changeMomentPage"></Page>
                </div>
              </div>

            </TabPane>
            <TabPane label="评论信息" name="comments">
              <Row>
                <Table border size="small" :columns="commentsColumns" :data="comments">
                  <template slot-scope="{ row }" slot="object_user">
                   <span v-show="undefined!==row.object_user">
                      <span @click="openUserDetailsModel(row.object_user.id)">
                        {{ undefined === row.object_user ? "" : row.object_user.nickname }}
                      </span>
                     ({{ undefined === row.object_user ? "" : row.object_user.id }})
                   </span>
                  </template>

                </Table>
              </Row>
              <div style="margin: 10px;overflow: hidden">
                <div style="float: left;">
                  <Page :total="commentPageTotal" :page-size="20" show-elevator show-total
                        @on-change="changeCommentPage"></Page>
                </div>
              </div>
            </TabPane>
            <TabPane label="系统消息" name="messages">
              <Row>
                <Table border size="small" :columns="messagesColumns" :data="messages">
                  <template slot-scope="{ row }" slot="object_user">
                   <span v-show="undefined!==row.object_user">
                      <span @click="openUserDetailsModel(row.object_user.id)">
                        {{ undefined === row.object_user ? "" : row.object_user.nickname }}
                      </span>
                     ({{ undefined === row.object_user ? "" : row.object_user.id }})
                   </span>
                  </template>

                </Table>
              </Row>
              <div style="margin: 10px;overflow: hidden">
                <div style="float: left;">
                  <Page :total="messagePageTotal" show-elevator show-total @on-change="changeMessagePage"></Page>
                </div>
              </div>
            </TabPane>
            <TabPane label="封禁信息" name="bans">
              <Row>
                <Table border size="small" :columns="bansColumns" :data="bans">
                </Table>
              </Row>
              <div style="margin: 10px;overflow: hidden">
                <div style="float: left;">
                  <Page :total="banPageTotal" show-elevator show-total @on-change="changeBanPage"></Page>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Modal>
  </div>
</template>


<script>
export default {
  name: "userDetails",
  props: ['id'],
  watch: {
    id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.tabName = "details"
        this.getUserDetails();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', ()=>{
        if (window.innerWidth<=500){
          this.modelWidth="100%"
        }else {
          this.modelWidth=900
        }
      });
    })
  },
  data() {
    return {
      tabName: "details",
      show: true,
      loading: false,
      user: {},

      phrases: [
        "同学你好:",
        "请将交易类信息发布到[应用]标签下的[交易]模块中。",
        "请将兼职类信息发布到[应用]标签下的[交易]模块中。",
        "若再违反此规则，平台将采取相应措施对账号进行处理",
        "请注意文明用语，",
        "请勿进行人身攻击，",
        "我们已经对该内容和该用户做出了一定的处理",
        "感谢你的举报",
      ],
      send_message_content: "",


      momentColumns: [
        {
          title: 'id', width: 84, key: 'id', render: (h, params) => {
            return h('span', {on: {click: () => {this.$momentDetails({id: params.row.id})}}}, params.row.id)
          }
        },
        {
          title: '内容',
          width: 400,
          key: 'content',
          render: (h, params) => {
            return h('span', {
              on: {
                click: () => {
                  this.$momentDetails({id: params.row.id})
                }
              }
            }, params.row.content)
          }
        },
        {
          title: '信息',
          width: 120,
          key: 'images',
          render: (h, params) => {
            var subject = this.getMomentSubjectName(params.row.subject_id)
            var anonymous = 1 === params.row.anonymous ? '匿名' : '公开';
            var image_length = params.row.images.length;
            var video_length = params.row.video.length;
            var audio_length = params.row.audio.length;

            var comments_count = params.row.comments_count;
            var praise_users_count = params.row.praise_users_count;

            return h('div', [
              h('p', subject + "-" + anonymous),
              h('p', "图:" + image_length + "-视:" + video_length + "-音:" + audio_length),
              h('p', "评:" + comments_count + "-赞:" + praise_users_count)
            ])
          }
        },
        {title: '发布时间', width: 160, key: 'created_at'},
        {
          title: '删除时间',
          key: 'action',
          width: 160,
          align: 'center',
          render: (h, params) => {
            let del_button = h('Poptip', {
              props: {
                confirm: true,
                transfer: true,
                title: '你确定要删除吗？'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                'on-ok': () => {
                  this.delMoment(params.row.id)
                }
              }
            }, [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
              }, '删除')
            ]);
            let divs = [];
            if (!params.row.deleted_at) {
              divs.push(del_button);
            } else {
              divs.push(h('span', params.row.deleted_at))
            }
            return h('div', divs);
          }
        }
      ],
      moments: [],
      momentNowPage: 1,//当前页
      momentPageTotal: 0,//总页数

      bansColumns: [
        {
          title: '时间', width: 340, key: 'time',
          render: (h, params) => {
            let created_at = this.$moment(params.row.created_at);
            let end_at = this.$moment(params.row.end_at);
            let now = this.$moment();
            let d = end_at.diff(created_at, 'days')
            if (now.isBefore(end_at)) {
              return h('Tag', {props: {color: "success"}}, params.row.created_at + "-" + params.row.end_at + " " + d + "天")
            } else {
              return h('Tag', {props: {color: "default"}}, params.row.created_at + "-" + params.row.end_at + " " + d + "天")
            }
          }
        },
        {
          title: '目标', width: 80, key: 'target',
          render: (h, params) => {
            switch (params.row.target) {
              case 1:
                return h('span', "校友圈")
              case 2:
                return h('span', "评论")
              case 3:
                return h('span', "私聊")
            }
          }
        },
        {
          title: '理由', width: 300, key: 'target',
          render: (h, params) => {
            return h('span', params.row.reason + params.row.desc)
          }
        },
        {
          title: '操作',
          key: 'action',
          width: 160,
          align: 'center',
          render: (h, params) => {
            let del_button = h('Poptip', {
              props: {
                confirm: true,
                transfer: true,
                title: '你确定要结束吗？'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                'on-ok': () => {
                  this.$api.UserCloseBan(params.row.id,this.getBans)
                }
              }
            }, [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
              }, '结束')
            ]);
            let divs = [];
            if (this.$moment().isBefore(this.$moment(params.row.end_at))) {
              divs.push(del_button);
            } else {
              divs.push(h('span', ''))
            }
            return h('div', divs);
          }
        }
      ],
      bans: [],
      banNowPage: 1,//当前页
      banPageTotal: 0,//总页数
      addBanChooseDateOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
        shortcuts: [
          {
            text: '1天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              return date;
            }
          },
          {
            text: '2天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
              return date;
            }
          },
          {
            text: '3天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
              return date;
            }
          },
          {
            text: '1周',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              return date;
            }
          },
          {
            text: '2周',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 15);
              return date;
            }
          },
          {
            text: '1月',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              return date;
            }
          },
          {
            text: '2月',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 60);
              return date;
            }
          },
        ]
      },
      banItem: {
        user_id: "",
        targets: [1, 2, 3],
        reason: "未准守平台要求将广告、兼职等交易内容发布到交易区的规定",
        desc: "",
        end_at: "",
      },

      commentsColumns: [
        {title: 'id', width: 90, key: "id"},
        {
          title: '动态', width: 90, key: 'moment_id',
          render: (h, params) => {
            return h('span', {
              on: {
                click: () => {
                  this.$momentDetails({id: params.row.moment_id})
                }
              }
            }, params.row.moment_id)
          }
        },
        {title: '内容', width: 300, key: "content"},
        {title: '对象', width: 160, slot: "object_user"},
        {title: '时间', width: 160, key: "created_at"},
        {
          title: '删除时间',
          key: 'action',
          width: 160,
          align: 'center',
          render: (h, params) => {
            let del_button = h('Poptip', {
              props: {
                confirm: true,
                transfer: true,
                title: '你确定要删除吗？'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                'on-ok': () => {
                  this.delComment(params.row.id)
                }
              }
            }, [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
              }, '删除')
            ]);
            let divs = [];
            if (!params.row.deleted_at) {
              divs.push(del_button);
            } else {
              divs.push(h('span', params.row.deleted_at))
            }
            return h('div', divs);
          }
        }
      ],
      comments: [],
      commentNowPage: 1,//当前页
      commentPageTotal: 0,//总页数

      messagesColumns: [
        {
          title: '类型', width: 60, key: 'content',
          render: (h, params) => {
            return h('span', params.row.content.type)
          }
        },
        {
          title: '动作', width: 60, key: 'content',
          render: (h, params) => {
            return h('span', params.row.content.action)
          }
        },
        {title: '时间', width: 160, key: "created_at"},
        {
          title: '内容', width: 700, key: 'content',
          render: (h, params) => {
            return h('span', params.row.content.content)
          }
        },

      ],
      messages: [],
      messageNowPage: 1,//当前页
      messagePageTotal: 0,//总页数

      modelWidth:900

    }
  },
  created() {
    if (window.screen.width<=500){
      this.modelWidth="100%"
    }
  },
  methods: {
    handelTabClick(name) {
      if (!this.id) {
        return
      }
      switch (name) {
        case "details":
          this.getUserDetails();
          break;
        case "moments":
          this.messageNowPage = 1
          this.getMoments();
          break;
        case "comments":
          this.commentNowPage = 1
          this.getComments();
          break;
        case "bans":
          this.banNowPage = 1
          this.getBans();
          break;
        case "messages":
          this.messageNowPage = 1
          this.getMessages();
          break;
      }
    },
    getUserDetails() {
      if (this.id <= 0) {
        return
      }
      this.user = {};
      this.loading = true;
      this.show = true;
      this.$http.get("/user/" + this.id).then(res => {
        this.user = res.data.data;
        this.loading = false;
      });
    },

    getGenderString(gender) {
      switch (gender) {
        case 1:
          return "男";
        case 2:
          return "女";
        default:
          return "未知";
      }
    },

    //动态
    getMoments() {
      this.moments = [];
      this.$http.get("/moments", {
        params: {
          user_id: this.id,
          page: this.momentNowPage,
          per_page: 20,
          deleted: 0,
        }
      }).then((res) => {
        console.log(res.data);
        this.moments = res.data.data;
        this.momentPageTotal = res.data.meta.pagination.total;
      });
    },
    changeMomentPage(page) {
      this.momentNowPage = page;
      this.getMoments();
    },
    delMoment(id) {
      this.$http.delete('/moments/' + id).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.getMoments();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },

    //评论
    changeCommentPage(page) {
      this.commentNowPage = page;
      this.getComments();
    },
    getComments() {
      this.comments = [];
      this.$http.get("/moments/comments", {
        params: {
          page: this.commentNowPage,
          per_page: 20,
          user_id: this.id,
        }
      }).then((res) => {
        this.comments = res.data.data;
        this.commentPageTotal = res.data.meta.pagination.total;
      });
    },
    delComment(commentId) {
      this.$http.delete('/moments/comment/' + commentId).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '删除成功'
          });
          this.getComments();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      });
    },

    //系统消息
    changeMessagePage(page) {
      this.messageNowPage = page;
      this.getMessages();
    },
    getMessages() {
      this.messages = [];
      this.$http.get("/messages", {
        params: {
          page: this.messageNowPage,
          per_page: 10,
          sender_id: 0,
          receiver_id: this.id,
        }
      }).then((res) => {
        this.messages = res.data.data;
        this.messagePageTotal = res.data.meta.pagination.total;
      });
    },

    //封禁
    changeBanPage(page) {
      this.banNowPage = page;
      this.getBans();
    },
    getBans() {
      this.bans = [];
      this.$http.get("/bans", {
        params: {
          page: this.banNowPage,
          user_id: this.id,
        }
      }).then((res) => {
        this.bans = res.data.data;
        this.banPageTotal = res.data.meta.pagination.total;
      });
    },
    chooseBanDataChange(data) {
      this.banItem.end_at = data
    },
    addBanReasonChooseChange(value) {
      this.banItem.reason = value
    },
    addBan() {
      if (this.banItem.targets.length <= 0) {
        this.$Notice.error({
          title: '操作失败',
          desc: "请选择要封禁的对象"
        });
        return;
      }
      this.banItem.user_id = this.id
      this.$http.post('/bans', this.banItem).then((res) => {
        if (200 === res.data.code) {
          this.$Notice.success({
            title: '操作成功',
            desc: '添加成功'
          });
          this.banItem = {};
          this.banItem = {
            user_id: "",
            targets: [],
            reason: "",
            desc: "",
            end_at: "",
          };
          this.getDataList();
        } else {
          this.$Notice.error({
            title: '操作失败',
            desc: res.data.msg
          });
        }
      })
    },
    openUserDetailsModel(id) {
      this.$userDetails({id: id})
    },
  },

}
</script>

<style scoped>

</style>
